import { Point, Location, Pagination, Retrieval, Currency } from './commons.models'
import { FileDetails } from './files.models'
import { Organization } from './organizations.models'
import { AutocompletePrediction } from 'react-places-autocomplete'
import Constants from '../constants'

export enum CatalogType {
  deconstruction = 'deconstruction',
  storage = 'storage',
  construction = 'construction',
  needs = 'needs',
}

export const NeedsCatalogTypes = [CatalogType.construction, CatalogType.needs]
export const ResourcesCatalogTypes = [CatalogType.deconstruction, CatalogType.storage]
export const isNeeds = (type: CatalogType) =>
  !!NeedsCatalogTypes.find((needsType: CatalogType) => type === needsType)
export const isResources = (type: CatalogType) =>
  !!ResourcesCatalogTypes.find((resourcesType: CatalogType) => type === resourcesType)

export enum CatalogIcon {
  none = 'none',
  raedificare = 'raedificare',
  lifeWasteToBuild = 'lifeWasteToBuild',
}

export enum BuildingType {
  individualHousing = 'individualHousing',
  collectiveHousing = 'collectiveHousing',
  shops = 'shops',
  offices = 'offices',
  health = 'health',
  educational = 'educational',
  catering = 'catering',
  sparetime = 'sparetime',
  industry = 'industry',
  environment = 'environment',
  scene = 'scene',
  other = 'other',
}

export enum InterventionType {
  demolition = 'demolition',
  rehabilitation = 'rehabilitation',
  construction = 'construction',
  layoutVRD = 'layoutVRD',
}

export enum ConstructionInterventionType {
  rehabilitation = 'rehabilitation',
  construction = 'construction',
  layoutVRD = 'layoutVRD',
}
export enum DeconstructionInterventionType {
  demolition = 'demolition',
  rehabilitation = 'rehabilitation',
  layoutVRD = 'layoutVRD',
}

export enum NetworkStatus {
  local = 'local',
  remote = 'remote',
  sync = 'sync',
}

export enum CatalogStatus {
  draft = 'draft',
  pending = 'pending',
  accepted = 'accepted',
}
export const getCatalogStatusColor = (status: CatalogStatus) => {
  switch (status) {
    case CatalogStatus.draft:
      return Constants.colors.secondaryText
    case CatalogStatus.pending:
      return Constants.colors.primary
    case CatalogStatus.accepted:
      return Constants.colors.success
    default:
      return Constants.colors.black
  }
}

export const getCatalogTypeColor = (type: CatalogType) => {
  switch (type) {
    case CatalogType.deconstruction:
    case CatalogType.storage:
      return 'primary'
    case CatalogType.construction:
    case CatalogType.needs:
      return 'secondary'
  }
}

export interface Room {
  _id: string
  name: string
  points: Point[]
}

export interface PlanRoom extends Room {
  isTmp?: boolean
  pointIndex?: number
  lineIndex?: number
  closed?: boolean
  isSelected?: boolean
}
export interface Plan {
  _id: string
  name: string
  file: FileDetails
  scale: number
  angle: number
  rooms: Room[]
}
export interface ManagePlan extends Partial<Omit<Plan, 'file' | '_id'>> {
  _id?: string
  file: File
}

export interface ManageCatalog extends Partial<Omit<Catalog, '_id' | 'plans'>> {
  _id?: string
  plans?: (ManagePlan | Plan)[]
  locationPrediction?: AutocompletePrediction
  mainImageFileFile?: File
  imageFilesFile?: File[]
  diagnosticFilesFile?: File[]
  otherFilesFile?: File[]
}
export interface CatalogLight {
  _id: string
  enableCerfa?: boolean
  type: CatalogType
  currency: Currency
  buildingType?: BuildingType
  organization: Organization
  interventionTypes?: InterventionType[]
  customProjectOwner?: string
  projectOwner?: Organization
  catalogIcon?: CatalogIcon
  name: string
  status: CatalogStatus
  location: Location
  mainImageFile: FileDetails
  retrieval: Retrieval
  networkStatus: NetworkStatus
  retrievalInformation?: string
  acceptedAt: Date
  lastSyncAt?: Date
  localUpdatedAt?: Date
  clientChatToReadCount?: number
  sellerChatToReadCount?: number
}

export interface Catalog extends CatalogLight {
  visible: boolean
  createdBy: Organization
  buildingSurface?: number
  designer?: string
  amoReuse?: string
  constructionDate?: Date
  originalActivity?: string
  amountOfWork?: number
  percentageOfNeed?: number
  operationContext?: string
  hoursDedicatedToReuse?: number
  plans: Plan[]
  imageFiles: FileDetails[]
  diagnosticFiles: FileDetails[]
  otherFiles: FileDetails[]
  hideValidationWeight?: boolean
}

export interface ApiPlan extends Omit<Plan, 'file' | '_id' | 'rooms'> {
  _id?: string
  file: string
  rooms: { _id?: string; name: string; points: Point[] }[]
}

export interface ApiCatalog
  extends Omit<
    Catalog,
    | '_id'
    | 'organization'
    | 'projectOwner'
    | 'mainImageFile'
    | 'imageFiles'
    | 'plans'
    | 'diagnosticFiles'
    | 'otherFiles'
  > {
  _id?: string
  organization: string
  projectOwner?: string | null
  mainImageFile: string
  imageFiles: string[]
  plans: ApiPlan[]
  diagnosticFiles: string[]
  otherFiles: string[]
}

export interface CatalogsPagination extends Pagination {
  data: Catalog[]
}
export interface CatalogLightsPagination extends Pagination {
  data: CatalogLight[]
}
