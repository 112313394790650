import {
  alpha,
  Box,
  Chip,
  Button,
  styled,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  Menu as MenuIcon,
  ArrowBack as ArrowBackIcon,
  Download as DownloadIcon,
  Upload as UploadIcon,
  Add as AddIcon,
  Mail as MailIcon,
} from '@mui/icons-material'

import {
  Catalog,
  CatalogStatus,
  getCatalogStatusColor,
  getCatalogTypeColor,
  CatalogType,
  NetworkStatus,
  isResources,
  isNeeds,
} from '../../models/catalogs.models'

import { Actions } from '../../models/props.models'
import { Mode, Route } from '../../models/commons.models'
import useRoute from '../../hooks/useRoute.hooks'
import Constants from '../../constants'
import TabsComponent from '../common/Tabs.common'
import StyledChip from '../common/Chip.common'
import { sessionService } from '../../store/session'
import React, { useMemo } from 'react'
import IconCatalog from './Icon.catalog'
import constants from '../../constants'
import { MaterialType } from '../../models/materials.models'

const Container = styled(Box)<{ src?: string; height?: number }>(({ theme, src, height }) => ({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: `0 2px 20px 0 ${alpha(theme.palette.black, 0.05)}`,
  ...(src && {
    backgroundImage: `
    linear-gradient(to left, ${alpha(theme.palette.black, 0.4)} 0%, ${alpha(
      theme.palette.black,
      0.4,
    )} 100%),
    url(${src})
  `,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }),
  ...(height && {
    height: `${height}px`,
    minHeight: `${height}px`,
  }),
}))

const GoBackButton = styled(Button)(({ theme }) => ({
  left: '20px',
  [theme.breakpoints.down('md')]: {
    left: '10px',
  },
  [theme.breakpoints.only('xs')]: {
    left: '0',
  },
  position: 'absolute',
  top: '18px',
}))
const ChatButton = styled(Button)(({ theme }) => ({
  right: '20px',
  [theme.breakpoints.down('md')]: {
    right: '10px',
  },
  [theme.breakpoints.only('xs')]: {
    right: '0',
  },
  position: 'absolute',
  top: '18px',
}))

const CatalogName = styled(Typography)(() => ({
  fontSize: '1.563rem',
  textAlign: 'center',
  fontWeight: 700,
}))

interface CatalogHeaderProps {
  catalog: Catalog
  isPublic?: boolean
  isAdmin?: boolean
  showCerfa?: boolean
  onAction: (
    action: 'chat' | 'goBack' | 'csv' | 'xls' | 'pdf' | 'csv-d' | 'csv-pem' | 'add' | 'import',
  ) => void
}
const CatalogHeader: React.FC<CatalogHeaderProps> = ({
  showCerfa,
  catalog,
  isAdmin,
  isPublic,
  onAction,
}) => {
  const { getPath } = useRoute()
  const { t } = useTranslation()
  const theme = useTheme()
  const onlyXs = useMediaQuery(theme.breakpoints.only('xs'))
  const downMd = useMediaQuery(theme.breakpoints.down('md'))
  const downLg = useMediaQuery(theme.breakpoints.down('lg'))

  const showStatus = useMemo(() => {
    return sessionService.showStatus({ type: catalog.type, isPublic: !!isPublic })
  }, [catalog.type, isPublic])
  const materialType = useMemo(
    () =>
      catalog.type === CatalogType.construction || catalog.type === CatalogType.needs
        ? MaterialType.need
        : MaterialType.resource,
    [catalog.type],
  )
  const addActions = [
    {
      label: downLg && !downMd ? undefined : t(`materials:actions.add${materialType}.label`),
      icon: downMd ? undefined : <AddIcon color={downLg ? 'primary' : undefined} />,
      onClick: onAction.bind(null, 'add'),
    },
    ...(constants.mode !== Mode.app
      ? [
          {
            label: downLg && !downMd ? undefined : t(`materials:actions.import.label`),
            icon: downMd ? undefined : <UploadIcon color={downLg ? 'primary' : undefined} />,
            onClick: onAction.bind(null, 'import'),
          },
        ]
      : []),
  ]

  const actions: Actions[] | undefined = isPublic
    ? undefined
    : [
        ...(downMd ? [] : addActions),
        {
          label: downLg ? undefined : t('global:actions.download'),
          icon: downMd ? (
            <MenuIcon color={downLg ? 'primary' : undefined} />
          ) : (
            <DownloadIcon color={downLg ? 'primary' : undefined} />
          ),
          items: [
            ...(downMd ? addActions : []),
            { label: t('catalogs:actions.downloadCsv'), onClick: onAction.bind(null, 'csv') },
            ...(isResources(catalog.type)
              ? [
                  { label: t('catalogs:actions.downloadPdf'), onClick: onAction.bind(null, 'pdf') },
                  { label: t('catalogs:actions.downloadXls'), onClick: onAction.bind(null, 'xls') },
                  ...(showCerfa && catalog.type === CatalogType.deconstruction
                    ? [
                        {
                          label: t('catalogs:actions.downloadCsvPem'),
                          onClick: onAction.bind(null, 'csv-pem'),
                        },
                        {
                          label: t('catalogs:actions.downloadCsvD'),
                          onClick: onAction.bind(null, 'csv-d'),
                        },
                      ]
                    : []),
                ]
              : []),
          ],
        },
      ]

  let tabItems = [
    {
      label: t(`global:routes.workspaceCatalogInformations`),
      path: getPath({
        route: isPublic ? Route.publicCatalogInformations : Route.workspaceCatalogInformations,
        catalogId: catalog._id,
      }),
    },
    ...(catalog.type === CatalogType.deconstruction && !isPublic
      ? [
          {
            label: t(`global:routes.workspaceCatalogPlans`),
            path: getPath({ route: Route.workspaceCatalogPlans, catalogId: catalog._id }),
          },
        ]
      : []),
    ...(!isPublic ||
    !catalog.retrieval.endDate ||
    new Date().getTime() < new Date(catalog.retrieval.endDate).getTime()
      ? [
          {
            label: t(`global:routes.workspaceCatalogMaterials`),
            path: getPath({
              route: isPublic ? Route.publicCatalogMaterials : Route.workspaceCatalogMaterials,
              catalogId: catalog._id,
            }),
          },
        ]
      : []),
    ...(sessionService.showPublic(catalog.type) && !isPublic && constants.mode !== Mode.app
      ? [
          {
            label: t(`global:routes.workspaceCatalogChat`),
            path: getPath({ route: Route.workspaceCatalogChat, catalogId: catalog._id }),
          },
        ]
      : []),
    ...(isResources(catalog.type) && !isPublic && catalog.networkStatus !== NetworkStatus.local
      ? [
          {
            label: t(`global:routes.workspaceCatalogOrders`),
            path: getPath({ route: Route.workspaceCatalogOrders, catalogId: catalog._id }),
          },
          {
            label: t(`global:routes.workspaceCatalogReport`),
            path: getPath({ route: Route.workspaceCatalogReport, catalogId: catalog._id }),
          },
        ]
      : []),
    ...(isNeeds(catalog.type) && !isPublic
      ? [
          {
            label: t(`global:routes.workspaceCatalogMatching`),
            path: getPath({ route: Route.workspaceCatalogMatching, catalogId: catalog._id }),
          },
        ]
      : []),
  ]

  if (isPublic) {
    // place materials before informations for public-catalogs
    tabItems = tabItems.reverse()
  }

  const tabs = (
    <TabsComponent
      lightMode={isPublic}
      actions={actions}
      items={tabItems}
      sx={
        isPublic
          ? {
              position: 'absolute',
              bottom: '0',
              right: '0',
              left: '0',
              width: '100%',
              backgroundColor: alpha(Constants.colors.black, 0.25),
              backdropFilter: 'blur(3px)',
              borderRadius: '0 0 4px 4px',
            }
          : undefined
      }
    />
  )

  if (isAdmin) {
    return tabs
  }

  return (
    <>
      <Container
        src={isPublic ? catalog.mainImageFile.src || catalog.mainImageFile.path || '' : ''}
        height={isPublic ? Constants.ui.catalogHeaderSize : undefined}>
        <GoBackButton
          startIcon={<ArrowBackIcon htmlColor={isPublic ? Constants.colors.white : undefined} />}
          onClick={onAction.bind(null, 'goBack')}
          sx={{ color: isPublic ? Constants.colors.white : undefined }}>
          {onlyXs ? '' : t('catalogs:components.header.goBack')}
        </GoBackButton>
        {isPublic && (
          <ChatButton
            startIcon={<MailIcon htmlColor={Constants.colors.white} />}
            onClick={onAction.bind(null, 'chat')}
            sx={{ color: Constants.colors.white }}>
            {onlyXs ? '' : t('catalogs:components.header.chat')}
          </ChatButton>
        )}
        {catalog.catalogIcon && isPublic ? (
          <IconCatalog catalogIcon={catalog.catalogIcon} />
        ) : undefined}

        <Box margin="auto" flexWrap="wrap" padding={onlyXs ? '0 50px' : '0 200px'}>
          <CatalogName
            sx={
              isPublic
                ? { marginTop: '30px', padding: '0 20px', color: Constants.colors.white }
                : { marginTop: '15px' }
            }>
            {catalog.name}
          </CatalogName>
          <Box display="flex" justifyContent="center" marginBottom={isPublic ? '40px' : undefined}>
            {showStatus &&
              [CatalogStatus.draft, CatalogStatus.pending, CatalogStatus.accepted].includes(
                catalog.status,
              ) && (
                <StyledChip
                  full
                  color={getCatalogStatusColor(catalog.status)}
                  label={t(`catalogs:status.${catalog.status}`)}
                  sx={{ marginRight: '5px' }}
                />
              )}
            <Chip
              color={getCatalogTypeColor(catalog.type)}
              label={t(`catalogs:type.${catalog.type}`)}
              sx={{ marginRight: '5px' }}
            />
            {catalog.interventionTypes?.map((i) => (
              <Chip key={i} label={t(`catalogs:interventionType.${i}`)} />
            ))}
            {isPublic && tabs}
          </Box>
        </Box>
        {!isPublic && tabs}
      </Container>
    </>
  )
}
export default CatalogHeader
