import { Box, InputLabel, FormHelperText } from '@mui/material'
import { TextProps } from '../../../models/props.models'
import StyledInput from './Styled.input'
import { Info as InfoIcon } from '@mui/icons-material'
import Tooltip from '../Tooltip.common'
import Stack from '../Stack.common'

const TextFieldComponent: React.FC<TextProps> = (props): JSX.Element => {
  const {
    ariaLabel,
    disabled,
    endAdornment,
    error,
    onClick,
    onChange,
    onKeyPress,
    inputRef,
    label,
    minRows,
    multiline,
    pattern,
    placeholder,
    readOnly,
    required,
    rows,
    type,
    value,
    autocomplete,
    maxWidth,
    tooltip,
  } = props
  return (
    <Box
      display="flex"
      flexDirection="column"
      position="relative"
      width="100%"
      onClick={onClick}
      maxWidth={maxWidth}>
      {!!label && (
        <InputLabel error={!!error}>
          <Stack direction="row" alignItems="center" spacing="6px">
            <Box>{label + (required ? '*' : '')}</Box>
            {tooltip && (
              <Tooltip title={tooltip}>
                <InfoIcon color="primary" />
              </Tooltip>
            )}
          </Stack>
        </InputLabel>
      )}
      <StyledInput
        aria-label={ariaLabel}
        variant="outlined"
        fullWidth
        multiline={multiline}
        minRows={minRows}
        rows={rows}
        inputRef={inputRef}
        required={required}
        type={type}
        disabled={disabled}
        placeholder={!readOnly ? placeholder : undefined}
        value={value}
        autoComplete={autocomplete}
        onChange={(evt) => {
          let value = evt.target.value

          if (pattern && value && !new RegExp(pattern).test(value.toString())) {
            evt.target.value = value.slice(0, value.length - 1)
            return
          }

          onChange?.(value)
        }}
        onKeyPress={onKeyPress}
        focused={readOnly ? false : undefined}
        InputProps={{ readOnly, endAdornment }}
        inputProps={{ pattern, name: autocomplete }}
        error={!!error}
      />
      {typeof error === 'string' && (
        <FormHelperText
          error
          sx={{ position: 'absolute', bottom: '-2px', left: '5px', pointerEvents: 'none' }}>
          {error}
        </FormHelperText>
      )}
    </Box>
  )
}

export default TextFieldComponent
