import { useMemo, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

import { getUserStatusColor, User, UserType } from '../../models/users.models'
import { ColumnItem, ItemType } from '../../models/props.models'
import List, { ListModeType, ListProps, ListRef } from '../common/List.common'
import UserCard from './Card.user'
import { sessionService } from '../../store/session'
import useRoute from '../../hooks/useRoute.hooks'
import { Route } from '../../models/commons.models'

export interface UserListProps extends Omit<ListProps, 'modes'> {
  isOrganizationPage?: boolean
}
const UserList = forwardRef<ListRef, UserListProps>((props, ref) => {
  const { isOrganizationPage, ...listProps } = props
  const { t } = useTranslation()
  const { goTo } = useRoute()

  const showStatus = useMemo(() => {
    return sessionService.showStatus({ type: 'user' })
  }, [])

  const columns = useMemo<ColumnItem[]>(
    () => [
      ...(showStatus
        ? [
            (user: User | undefined) => ({
              label: t('users:attributes.status'),
              key: 'status',
              type: ItemType.chip,
              props: {
                full: true,
                color: user ? getUserStatusColor(user.status) : '',
                label: user ? t(`users:status.${user.status}`) : '',
              },
            }),
          ]
        : []),
      ...(isOrganizationPage
        ? []
        : [
            (user: User | undefined) => ({
              label: t('users:attributes.organization'),
              key: 'organization.name',
              type: ItemType.link,
              props: {
                onClick: (e: any) => {
                  if (user) {
                    goTo({
                      route: Route.organization,
                      organizationId: user?.organization._id,
                    })
                  }
                  e.stopPropagation()
                },
                children: user?.organization.name,
              },
            }),
          ]),
      { label: t('users:attributes.lastname'), key: 'lastname' },
      { label: t('users:attributes.firstname'), key: 'firstname' },
      { label: t('users:attributes.email'), key: 'email' },
      {
        label: t('users:attributes.phoneNumber'),
        key: 'phoneNumber',
      },
      {
        label: t('users:attributes.type'),
        key: 'type',
        formatValue: (type: UserType) => (type ? t(`users:types.${type}`) : ''),
      },
      {
        label: t('users:attributes.createdAt'),
        key: 'createdAt',
        formatValue: (createdAt: string) =>
          createdAt
            ? t('global:format.date', {
                date: new Date(createdAt),
              })
            : '-',
      },
    ],
    [t, showStatus, goTo, isOrganizationPage],
  )

  return (
    <List
      ref={ref}
      {...listProps}
      modes={[
        { key: 'UserTable', type: ListModeType.table, columns },
        { key: 'UserCard', type: ListModeType.card, card: UserCard },
      ]}
    />
  )
})
export default UserList
