import { Autocomplete, FormHelperText, Box, InputLabel, Chip } from '@mui/material'
import { AutocompleteProps } from '../../../models/props.models'
import StyledInput from './Styled.input'
import constants from '../../../constants'

const AutocompleteComponent: React.FC<AutocompleteProps> = (props): JSX.Element => {
  const {
    onChange,
    disabled,
    placeholder,
    readOnly,
    items,
    label,
    required,
    error,
    value = [],
  } = props
  return (
    <Box display="flex" flexDirection="column" position="relative">
      {!!label && <InputLabel error={!!error}>{label + (required ? '*' : '')}</InputLabel>}
      <Autocomplete
        value={value ?? []}
        multiple
        freeSolo
        options={items}
        disabled={disabled}
        readOnly={readOnly}
        renderInput={(params) => (
          <StyledInput
            {...params}
            required={required}
            error={!!error}
            placeholder={value?.length > 0 ? '' : placeholder}
          />
        )}
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              sx={{ color: constants.colors.primary }}
              color="primary"
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        onChange={(_, newVal: string[]) => {
          onChange?.(newVal)
        }}
      />
      {typeof error === 'string' && (
        <FormHelperText
          error
          sx={{ position: 'absolute', bottom: '-2px', left: '5px', pointerEvents: 'none' }}>
          {error}
        </FormHelperText>
      )}
    </Box>
  )
}

export default AutocompleteComponent
