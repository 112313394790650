import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Assembly,
  Material,
  MaterialType,
  TermsOfSale,
  convert2Imperials,
  dimensionsToString,
} from '../../models/materials.models'
import { ItemType } from '../../models/props.models'
import { Catalog } from '../../models/catalogs.models'
import List, { ListMode, ListModeType, ListProps, ListRef } from '../common/List.common'
import MaterialCard from './Card.material'
import { StringUtils } from '../../utils/commons.utils'
import useRoute from '../../hooks/useRoute.hooks'
import { Pagination, Route } from '../../models/commons.models'
import { traduceCategory } from '../../models/categories.models'
import { Button } from '@mui/material'
import constants from '../../constants'
import { materialsService } from '../../store/materials'

export interface MaterialListProps extends Omit<ListProps, 'modes'> {
  type: MaterialType
  canUpdate?: boolean
  isAdmin?: boolean
  organization?: string
  isPublic?: boolean
  isCatalogPage?: boolean
  addToCart?: (material: Material, quantity: number) => void
  useImperials: boolean
  showCerfa: boolean
}

const MaterialList = forwardRef<ListRef & { parse: 'pem' | 'd' | undefined }, MaterialListProps>(
  (props, outerRef) => {
    const {
      useImperials,
      type,
      canUpdate,
      isAdmin,
      isPublic,
      isCatalogPage,
      addToCart,
      organization,
      showCerfa,
      getValues: getMaterials,
      getSecondListValues: getTransferedMaterials,
      getCardKey: getCardKeyProps,
      ...listProps
    } = props
    const { t } = useTranslation()
    const { goTo } = useRoute()

    const innerRef = useRef<ListRef>(null)
    useImperativeHandle(outerRef, () => ({
      ...innerRef.current!,
      parse: (innerRef?.current?.modeKey === 'materialPemTable'
        ? 'pem'
        : innerRef?.current?.modeKey === 'materialDTable'
        ? 'd'
        : undefined) as 'pem' | 'd' | undefined,
    }))

    // Only possible when isCatalogPage since all materials are loaded
    const getCardKey = useMemo(() => {
      return (
        getCardKeyProps ??
        (innerRef?.current?.modeKey === 'materialDTable'
          ? (material: Material & { wasteIndex: number }) =>
              `${material._id}-${material.wasteIndex}`
          : undefined)
      )
    }, [getCardKeyProps])
    const filterList = useCallback((materialPaginaged: Pagination): Pagination => {
      const parse =
        innerRef?.current?.modeKey === 'materialPemTable'
          ? 'pem'
          : innerRef?.current?.modeKey === 'materialDTable'
          ? 'd'
          : undefined
      if (parse) {
        const materials = materialsService.parseList(parse, materialPaginaged.data)

        return { total: materials.length, count: materials.length, data: materials }
      }
      return materialPaginaged
    }, [])
    const getValues = useCallback(
      async (filter: any) => filterList(await getMaterials(filter)),
      [filterList, getMaterials],
    )
    const getSecondListValues = useMemo(
      () =>
        getTransferedMaterials
          ? async (filter: any) => filterList(await getTransferedMaterials(filter))
          : undefined,
      [filterList, getTransferedMaterials],
    )

    const modes = useMemo<ListMode[]>(() => {
      return [
        ...((!isPublic
          ? [
              ...(isCatalogPage && showCerfa && type === MaterialType.resource
                ? [
                    {
                      key: 'materialPemTable',
                      type: ListModeType.table,
                      columns: [
                        (material: Material | undefined) => ({
                          label: t('materials:attributes.cerfaResource.category'),
                          key: 'categories',
                          formatValue: (_: any) => {
                            if (!material?.cerfaResource?.primaryCategory) {
                              return ''
                            }
                            return traduceCategory(
                              'resource',
                              material.cerfaResource?.tertiaryCategory ??
                                material.cerfaResource?.secondaryCategory ??
                                material.cerfaResource?.primaryCategory,
                            )
                          },
                        }),
                        {
                          label: t('materials:attributes.cerfaResource.description'),
                          key: 'name',
                        },
                        (material: Material | undefined) => ({
                          label: t('materials:attributes.cerfaResource.quantity'),
                          key: 'initialQty',
                          formatValue: () => {
                            const converted = (
                              useImperials && material ? convert2Imperials(material) : material
                            ) as Material | undefined
                            return `${StringUtils.formatNumber(converted?.initialQty ?? 0)} ${t(
                              `materials:unitSymbol.${converted?.unit ?? 'u'}`,
                            )}`
                          },
                        }),
                        {
                          label: t('materials:attributes.cerfaResource.dimensions'),
                          key: 'dimensions',
                          formatValue: (dimensions: any) => dimensionsToString(dimensions),
                        },
                        {
                          label: t('materials:attributes.cerfaResource.assembly'),
                          key: 'cerfaResource.assembly',
                          formatValue: (assembly: Assembly) =>
                            assembly ? t(`materials:assembly.${assembly}`) : '',
                        },
                        {
                          label: t('materials:attributes.cerfaResource.hazardousSuspiction'),
                          key: 'cerfaResource.hazardousSuspiction',
                          type: ItemType.checkbox,
                        },
                        {
                          label: t('materials:attributes.cerfaResource.constituentMaterials'),
                          key: 'cerfaResource.constituentMaterials',
                          formatValue: (constituentMaterials: string[]) =>
                            (constituentMaterials ?? []).join(', '),
                        },
                        {
                          label: t('materials:attributes.valid'),
                          key: 'valid',
                        },
                      ],
                      icon: (selected: boolean) => (
                        <Button
                          size="small"
                          variant="outlined"
                          color="primary"
                          sx={{
                            margin: '8px',
                            backgroundColor: selected
                              ? constants.colors.primary
                              : constants.colors.white,
                            color: selected ? constants.colors.white : constants.colors.primary,
                          }}>
                          PEM
                        </Button>
                      ),
                    },
                    {
                      key: 'materialDTable',
                      type: ListModeType.table,
                      columns: [
                        {
                          label: t('materials:attributes.name'),
                          key: 'name',
                        },
                        (material: Material | undefined) => ({
                          label: t('materials:attributes.cerfaWaste.category'),
                          key: 'categories',
                          formatValue: (_: any) => {
                            if (!material?.cerfaWaste?.[0]?.primaryCategory) {
                              return ''
                            }
                            let primary = traduceCategory(
                              'waste',
                              material.cerfaWaste?.[0]?.primaryCategory,
                              true,
                            )
                            let specific =
                              material.cerfaWaste?.[0]?.tertiaryCategory ??
                              material.cerfaWaste?.[0]?.secondaryCategory

                            if (specific) {
                              return `${primary} : ${traduceCategory('waste', specific)}`
                            }
                            return primary
                          },
                        }),
                        {
                          label: t('materials:attributes.cerfaWaste.wasteCode'),
                          key: 'cerfaWaste.0.wasteCode',
                        },
                        (material: Material | undefined) => ({
                          label: t('materials:attributes.cerfaWaste.weight'),
                          key: 'cerfaWeight',
                          formatValue: (_: any) => {
                            return !material?.unitWeight || !material?.totalQty
                              ? ''
                              : (material.unitWeight * material.totalQty) / 1000
                          },
                        }),
                        {
                          label: t('materials:attributes.cerfaWaste.wasteStreamsOutlets'),
                          key: 'cerfaWaste.0.wasteStreamsOutlets',
                          type: ItemType.checkbox,
                        },
                        {
                          label: t('materials:attributes.cerfaWaste.reusePercent'),
                          key: 'cerfaWaste.0.reusePercent',
                          format: (percent?: number) => (percent ? `${percent} %` : ''),
                        },
                        {
                          label: t('materials:attributes.valid'),
                          key: 'valid',
                        },
                      ],
                      icon: (selected: boolean) => (
                        <Button
                          size="small"
                          variant="outlined"
                          color="primary"
                          sx={{
                            margin: '8px',
                            backgroundColor: selected
                              ? constants.colors.primary
                              : constants.colors.white,
                            color: selected ? constants.colors.white : constants.colors.primary,
                          }}>
                          D
                        </Button>
                      ),
                    },
                  ]
                : []),
              {
                key: 'materialTable',
                type: ListModeType.table,
                columns: [
                  {
                    label: t('materials:attributes.name'),
                    key: 'name',
                  },
                  (material: Material | undefined) => ({
                    label: t('materials:attributes.categories'),
                    key: 'categories',
                    formatValue: (_: any) => {
                      return !material
                        ? ''
                        : `
                  ${traduceCategory('rae', material.primaryCategory)} /
                  ${traduceCategory('rae', material.secondaryCategory)} /
                  ${traduceCategory('rae', material.tertiaryCategory)}
                `
                    },
                  }),
                  ...(organization || isCatalogPage
                    ? []
                    : [
                        (material: Material | undefined) => ({
                          label: t('materials:attributes.organization'),
                          key: 'organization',
                          formatValue: (_: any) =>
                            `${(material?.catalog as Catalog)?.organization?.name || ''}`,
                          type: isAdmin ? ItemType.link : ItemType.typo,
                          props: {
                            onClick: (e: any) => {
                              if (material) {
                                goTo({
                                  route: Route.organization,
                                  organizationId: material?.catalog.organization._id,
                                })
                              }
                              e.stopPropagation()
                            },
                          },
                        }),
                      ]),
                  ...(isCatalogPage
                    ? []
                    : [
                        (material: Material | undefined) => ({
                          label: t('materials:attributes.catalog'),
                          key: 'catalog',
                          formatValue: (catalog: Catalog) => `${catalog?.name || ''}`,
                          type: isAdmin ? ItemType.link : ItemType.typo,
                          props: {
                            onClick: (e: any) => {
                              if (material) {
                                goTo({
                                  route: Route.workspaceCatalog,
                                  catalogId: material?.catalog._id,
                                })
                              }
                              e.stopPropagation()
                            },
                          },
                        }),
                      ]),
                  ...(type === MaterialType.need
                    ? [
                        (material: Material | undefined) => ({
                          label: t('materials:attributes.desiredQty'),
                          key: 'initialQty',
                          formatValue: () => {
                            const converted = (
                              useImperials && material ? convert2Imperials(material) : material
                            ) as Material | undefined
                            return `${StringUtils.formatNumber(converted?.initialQty ?? 0)} ${t(
                              `materials:unitSymbol.${converted?.unit ?? 'u'}`,
                            )}`
                          },
                        }),
                      ]
                    : [
                        ...(showCerfa
                          ? [
                              (material: Material | undefined) => ({
                                label: t('materials:attributes.quantities.total'),
                                key: 'totalQty',
                                formatValue: () => {
                                  const converted = (
                                    useImperials && material
                                      ? convert2Imperials(material)
                                      : material
                                  ) as Material | undefined
                                  return `${StringUtils.formatNumber(converted?.totalQty ?? 0)} ${t(
                                    `materials:unitSymbol.${converted?.unit ?? 'u'}`,
                                  )}`
                                },
                              }),
                            ]
                          : []),
                        (material: Material | undefined) => ({
                          label: t('materials:attributes.quantities.initial'),
                          key: 'initialQty',
                          formatValue: () => {
                            const converted = (
                              useImperials && material ? convert2Imperials(material) : material
                            ) as Material | undefined
                            return `${StringUtils.formatNumber(converted?.initialQty ?? 0)} ${t(
                              `materials:unitSymbol.${converted?.unit ?? 'u'}`,
                            )}`
                          },
                        }),
                        (material: Material | undefined) => ({
                          label: t('materials:attributes.quantities.current'),
                          key: 'currentQty',
                          formatValue: () => {
                            const converted = (
                              useImperials && material ? convert2Imperials(material) : material
                            ) as Material | undefined
                            return `${StringUtils.formatNumber(converted?.initialQty ?? 0)} ${t(
                              `materials:unitSymbol.${converted?.unit ?? 'u'}`,
                            )}`
                          },
                        }),
                        (material: Material | undefined) => ({
                          label: t('materials:attributes.price'),
                          key: 'price',
                          formatValue: (_: number) => {
                            const converted = (
                              useImperials && material ? convert2Imperials(material) : material
                            ) as Material | undefined
                            return converted?.visible === false
                              ? t('materials:termsOfSale.invisible')
                              : converted?.termsOfSale === TermsOfSale.sale
                              ? t('materials:details.price', {
                                  price: converted.price,
                                  currency: t(`global:currency.${converted.currency}`),
                                  unit: t(`materials:unitSymbol.${converted.unit}`),
                                })
                              : converted?.termsOfSale === TermsOfSale.donation
                              ? t('materials:details.donation')
                              : t('materials:details.priceNotDefined')
                          },
                        }),
                      ]),
                  (material: Material | undefined) => ({
                    label:
                      type === MaterialType.need
                        ? t('materials:attributes.needsEndDate')
                        : t('materials:attributes.retrieval.startDate'),
                    key: 'date',
                    formatValue: (_: number) =>
                      type === MaterialType.need
                        ? t('global:format.date', {
                            date: new Date(material?.retrieval?.endDate || 'invalid date'),
                          })
                        : material?.visible === false
                        ? ''
                        : `${t('global:format.date', {
                            date: new Date(material?.retrieval?.startDate || 'invalid date'),
                          })} - ${t('global:format.date', {
                            date: new Date(material?.retrieval?.endDate || 'invalid date'),
                          })}`,
                  }),
                ],
              },
            ]
          : []) as ListMode[]),
        {
          key: 'materialCard',
          type: ListModeType.card,
          card: (props: any) => (
            <MaterialCard
              {...props}
              useImperials={useImperials}
              isCatalogPage={isCatalogPage}
              isAdmin={isAdmin}
              showCerfa={showCerfa}
              isPublic={isPublic}
              addToCart={addToCart?.bind(null, props.value)}
            />
          ),
        },
      ]
    }, [
      t,
      type,
      isPublic,
      useImperials,
      goTo,
      isCatalogPage,
      addToCart,
      isAdmin,
      showCerfa,
      organization,
    ])

    return (
      <>
        <List
          ref={innerRef}
          {...listProps}
          getCardKey={getCardKey}
          getValues={getValues}
          getSecondListValues={getSecondListValues}
          modeKey={isAdmin && !isCatalogPage ? 'materialTable' : 'materialCard'}
          modes={modes}
        />
      </>
    )
  },
)
export default MaterialList
