import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Box,
  FormHelperText,
  InputLabel,
  Backdrop,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  IconButton,
  useMediaQuery,
  ListItemIcon,
  Divider,
  styled,
  InputAdornment,
  Checkbox,
} from '@mui/material'
import { useTheme } from '@mui/material/styles/index.js'
import StyledInput from '../common/input/Styled.input'
import {
  ArrowForwardIos as ArrowForwardIosIcon,
  ArrowBackIos as ArrowBackIosIcon,
  Close as CloseIcon,
} from '@mui/icons-material'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { SelectOption } from '../../models/props.models'

import { checkCategory, traduceCategory, getCategoryChoices } from '../../models/categories.models'

const Input = styled(StyledInput)({
  input: {
    textOverflow: 'ellipsis',
    direction: 'rtl',
    textAlign: 'left',
  },
})

export interface InputCategoryValue {
  primaryCategory?: number
  secondaryCategory?: number
  tertiaryCategory?: number
}

type InputCategoryProps = {
  type: 'rae' | 'resource' | 'waste'
  label?: string
  placeholder?: string
  disabled?: boolean
  readOnly?: boolean
  error?: string | boolean
  required?: boolean
  accurateCategory?: boolean
  multiple?: boolean
  value: InputCategoryValue | InputCategoryValue[]
  onChange: (value: InputCategoryValue | InputCategoryValue[]) => void
}

const paddingY = 4
const paddingX = 8
const optionWidth = 300
const maxWidth = 3 * (optionWidth + paddingX * 2)
const CategoryMenu = ({
  type,
  label,
  onClose,
  inputRef,
  accurateCategory,
  multiple,
  value,
  onChange,
}: InputCategoryProps & { onClose: () => void; inputRef: MutableRefObject<any> }) => {
  const theme = useTheme()
  const mediaDownSm = useMediaQuery(theme.breakpoints.down('sm'))
  const [forceSM, setForceSm] = useState<boolean>(false)
  const downSm = useMemo(() => {
    return mediaDownSm || forceSM
  }, [mediaDownSm, forceSM])
  const { t } = useTranslation()
  const menuRef = useRef<any>(null)

  const isTouch = 'ontouchstart' in document.documentElement
  const [opened, setOpened] = useState<{
    primary?: SelectOption
    secondary?: SelectOption
  }>({
    primary: undefined,
    secondary: undefined,
  })

  const [menuPosition, setMenuPosition] = useState<{
    top?: string
    left?: string
    bottom?: string
    right?: string
  }>({
    top: (inputRef.current?.getBoundingClientRect?.()?.bottom ?? 0) + paddingY + 'px',
    left: (inputRef.current?.getBoundingClientRect?.()?.left ?? 0) + 'px',
  })

  const getChildren = useCallback(
    (option?: SelectOption): SelectOption[] => getCategoryChoices(type, option?.value),
    [type],
  )
  const primaryCategoryOptions = useMemo<SelectOption[]>(() => getCategoryChoices(type), [type])
  const secondaryCategoryOptions = useMemo<SelectOption[]>(
    () => getChildren(opened.primary),
    [getChildren, opened],
  )
  const tertiaryCategoryOptions = useMemo<SelectOption[]>(
    () => getChildren(opened.secondary),
    [getChildren, opened],
  )

  useEffect(() => {
    if (!multiple) {
      const single = value as InputCategoryValue
      setOpened({
        primary: !single.secondaryCategory
          ? undefined
          : {
              label: traduceCategory(type, single.primaryCategory!),
              value: single.primaryCategory,
            },
        secondary: !single.tertiaryCategory
          ? undefined
          : {
              label: traduceCategory(type, single.secondaryCategory!),
              value: single.secondaryCategory,
            },
      })
    }
  }, [t, type, value, multiple])

  const maxHeight = useMemo(() => {
    let maxOption = primaryCategoryOptions.length
    primaryCategoryOptions.forEach((primary: SelectOption) => {
      let secondaries = getChildren(primary)
      maxOption = Math.max(maxOption, secondaries.length)
      secondaries.forEach((secondary: SelectOption) => {
        maxOption = Math.max(maxOption, getChildren(secondary).length)
      })
    })
    return maxOption * 37
  }, [primaryCategoryOptions, getChildren])

  useEffect(() => {
    function handleResize() {
      setTimeout(() => {
        const box = menuRef.current?.getBoundingClientRect?.()

        if (maxHeight > window.innerHeight || maxWidth > window.innerWidth) {
          setForceSm(true)
        } else if (box) {
          const top =
            box.top + maxHeight > window.innerHeight
              ? window.innerHeight - maxHeight - 5
              : (inputRef.current?.getBoundingClientRect?.()?.bottom ?? 0) + paddingY
          const left =
            box.left + maxWidth > window.innerWidth
              ? window.innerWidth - maxWidth - 5
              : inputRef.current?.getBoundingClientRect?.()?.left ?? 0

          setMenuPosition({
            top: `${top}px`,
            left: `${left}px`,
          })
        }
      })
    }

    if (!downSm) {
      handleResize()
      window.addEventListener('resize', handleResize)
      window.addEventListener('scroll', handleResize)
    } else {
      setMenuPosition({
        top: '0',
        left: '0',
        bottom: '0',
        right: '0',
      })
    }
    return () => {
      if (!downSm) {
        window.removeEventListener('resize', handleResize)
        window.removeEventListener('scroll', handleResize)
      }
    }
  }, [downSm, inputRef, forceSM, maxHeight])

  const select = useCallback(
    (
      primaryCategory?: SelectOption,
      secondaryCategory?: SelectOption,
      tertiaryCategory?: SelectOption,
    ) => {
      const newValue: InputCategoryValue = {
        primaryCategory: primaryCategory ? primaryCategory.value : undefined,
        secondaryCategory: secondaryCategory ? secondaryCategory.value : undefined,
        tertiaryCategory: tertiaryCategory ? tertiaryCategory.value : undefined,
      }
      let isAccurate =
        !!newValue.primaryCategory &&
        checkCategory(
          type,
          newValue.primaryCategory,
          newValue.secondaryCategory,
          newValue.tertiaryCategory,
        )

      if (!accurateCategory || isAccurate) {
        if (multiple) {
          if (!accurateCategory) {
            console.error('Multiple not accurate is not handle !')
            // checkbox must be had on each level
            // input.stringValue
          } else {
            const multipleVal = (value as InputCategoryValue[]) ?? []
            const selectedIndex = multipleVal.findIndex(
              (val: InputCategoryValue) => val?.tertiaryCategory === newValue.tertiaryCategory,
            )
            if (selectedIndex !== -1) {
              onChange?.(multipleVal.filter((_, i: number) => i !== selectedIndex))
            } else {
              onChange?.([...multipleVal, newValue])
            }
          }
        } else {
          onChange?.(newValue as any)
          onClose()
        }
      } else {
        setOpened(() => ({ primary: primaryCategory, secondary: secondaryCategory }))
      }
    },
    [type, accurateCategory, onChange, onClose, value, multiple],
  )

  return createPortal(
    <Backdrop sx={{ zIndex: 100000 }} open invisible onClick={onClose}>
      <Paper
        ref={menuRef}
        onClick={(e: any) => e.stopPropagation()}
        sx={{
          position: 'absolute',
          ...menuPosition,
          display: 'flex',
        }}>
        {(!opened.primary || !downSm) && (
          <List sx={{ width: downSm ? '100%' : undefined }}>
            {downSm && (
              <>
                <ListItem disablePadding>
                  <ListItemButton onClick={onClose}>
                    <ListItemIcon>
                      <CloseIcon />
                    </ListItemIcon>
                    {label}
                  </ListItemButton>
                </ListItem>
                <Divider />
              </>
            )}
            {primaryCategoryOptions?.map((primary: SelectOption) => (
              <ListItem
                disablePadding
                key={primary.value}
                secondaryAction={
                  isTouch && !accurateCategory && getChildren(primary).length > 0 ? (
                    <IconButton
                      edge="end"
                      onClick={(e: any) => {
                        e.stopPropagation()
                        setOpened(() => ({ primary: primary, secondary: undefined }))
                      }}>
                      <ArrowForwardIosIcon />
                    </IconButton>
                  ) : undefined
                }>
                <ListItemButton
                  selected={
                    opened.primary?.value === primary.value ||
                    (downSm &&
                      !multiple &&
                      (value as InputCategoryValue).primaryCategory === primary.value)
                  }
                  onClick={() => select(primary)}
                  onMouseOver={() => {
                    if (!downSm) {
                      setOpened(() => ({ primary: primary, secondary: undefined }))
                    }
                  }}
                  sx={{
                    paddingLeft: `${paddingX}px`,
                    paddingRight: `${paddingX}px`,
                    paddingTop: `${paddingY}px`,
                    paddingBottom: `${paddingY}px`,
                  }}>
                  <ListItemText
                    title={primary.label}
                    primary={primary.label}
                    sx={
                      downSm
                        ? {}
                        : {
                            textOverflow: 'ellipsis',
                            maxWidth: `${optionWidth}px`,
                            textWrap: 'nowrap',
                            overflow: 'hidden',
                          }
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
        {opened.primary && (!opened.secondary || !downSm) && (
          <Paper elevation={downSm ? 0 : 1} sx={{ width: downSm ? '100%' : undefined }}>
            <List>
              {downSm && (
                <>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() =>
                        setOpened(() => ({ primary: undefined, secondary: undefined }))
                      }>
                      <ListItemIcon>
                        <ArrowBackIosIcon />
                      </ListItemIcon>
                      {opened.primary.label}
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                </>
              )}
              {secondaryCategoryOptions?.map((secondary) => (
                <ListItem
                  disablePadding
                  key={secondary.value}
                  secondaryAction={
                    isTouch && !accurateCategory && getChildren(secondary).length > 0 ? (
                      <IconButton
                        edge="end"
                        onClick={(e: any) => {
                          e.stopPropagation()
                          setOpened((opened: any) => ({
                            ...opened,
                            secondary: secondary,
                          }))
                        }}>
                        <ArrowForwardIosIcon />
                      </IconButton>
                    ) : undefined
                  }>
                  <ListItemButton
                    selected={
                      opened.secondary?.value === secondary.value ||
                      (downSm &&
                        !multiple &&
                        (value as InputCategoryValue).secondaryCategory === secondary.value)
                    }
                    onClick={() => select(opened.primary, secondary)}
                    onMouseOver={() => {
                      if (!downSm) {
                        setOpened((opened: any) => ({ ...opened, secondary: secondary }))
                      }
                    }}
                    sx={{
                      paddingLeft: `${paddingX}px`,
                      paddingRight: `${paddingX}px`,
                      paddingTop: `${paddingY}px`,
                      paddingBottom: `${paddingY}px`,
                    }}>
                    <ListItemText
                      title={secondary.label}
                      primary={secondary.label}
                      sx={
                        downSm
                          ? {}
                          : {
                              textOverflow: 'ellipsis',
                              maxWidth: `${optionWidth}px`,
                              textWrap: 'nowrap',
                              overflow: 'hidden',
                            }
                      }
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Paper>
        )}
        {opened.secondary && (
          <Paper elevation={downSm ? 0 : 1} sx={{ width: downSm ? '100%' : undefined }}>
            <List>
              {downSm && (
                <>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => setOpened((opened) => ({ ...opened, secondary: undefined }))}>
                      <ListItemIcon>
                        <ArrowBackIosIcon />
                      </ListItemIcon>
                      {opened.secondary.label}
                    </ListItemButton>
                  </ListItem>{' '}
                  <Divider />
                </>
              )}
              {tertiaryCategoryOptions?.map((tertiary) => (
                <ListItem disablePadding key={tertiary.value}>
                  <ListItemButton
                    selected={
                      !multiple && (value as InputCategoryValue).tertiaryCategory === tertiary.value
                    }
                    onClick={() => select(opened.primary, opened.secondary, tertiary)}
                    sx={{
                      paddingLeft: `${paddingX}px`,
                      paddingRight: `${paddingX}px`,
                      paddingTop: `${paddingY}px`,
                      paddingBottom: `${paddingY}px`,
                    }}>
                    {multiple && (
                      <Checkbox
                        color="primary"
                        checked={(value as InputCategoryValue[])?.some(
                          (x) => x?.tertiaryCategory === tertiary.value,
                        )}
                        sx={{ marginRight: '5px' }}
                      />
                    )}
                    <ListItemText
                      title={tertiary.label}
                      primary={tertiary.label}
                      sx={
                        downSm
                          ? {}
                          : {
                              textOverflow: 'ellipsis',
                              maxWidth: `${optionWidth}px`,
                              textWrap: 'nowrap',
                              overflow: 'hidden',
                            }
                      }
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Paper>
        )}
      </Paper>
    </Backdrop>,
    document.getElementById('category-modal') as HTMLElement,
  )
}

const InputCategory: React.FC<InputCategoryProps> = (props) => {
  const {
    type,
    multiple,
    value,
    error,
    label,
    placeholder,
    required,
    disabled,
    readOnly,
    onChange,
  } = props

  const inputRef = useRef<any>(null)
  const [open, setOpen] = useState<boolean>(false)

  const stringValue = useMemo(() => {
    let traduceInput = (
      input: InputCategoryValue,
    ): { primary: string; secondary: string; tertiary: string } => ({
      primary: input.primaryCategory ? traduceCategory(type, input.primaryCategory) : '',
      secondary: input.secondaryCategory ? traduceCategory(type, input.secondaryCategory) : '',
      tertiary: input.tertiaryCategory ? traduceCategory(type, input.tertiaryCategory) : '',
    })
    if (multiple) {
      return (
        (value as InputCategoryValue[])
          ?.map((val: InputCategoryValue) => {
            const { primary, secondary, tertiary } = traduceInput(val)
            return tertiary || secondary || primary || ''
          })
          .join(', ') ?? ''
      )
    }

    const { primary, secondary, tertiary } = traduceInput(value as InputCategoryValue)
    return !primary
      ? ''
      : primary + (!secondary ? '' : ` / ${secondary}` + (!tertiary ? '' : ` / ${tertiary}`))
  }, [value, multiple, type])

  return (
    <Box display="flex" flexDirection="column">
      {!!label && <InputLabel error={!!error}>{label + (required ? '*' : '')}</InputLabel>}
      <Input
        color="primary"
        variant="outlined"
        ref={inputRef}
        fullWidth
        required={required}
        disabled={disabled}
        InputProps={{
          readOnly: true,
          endAdornment:
            (!required && !!(value as InputCategoryValue).primaryCategory) ||
            (value as InputCategoryValue[])?.[0]?.primaryCategory ? (
              <InputAdornment position="end">
                <IconButton
                  onClick={(e) => {
                    onChange?.(
                      multiple
                        ? []
                        : {
                            primaryCategory: undefined,
                            secondaryCategory: undefined,
                            tertiaryCategory: undefined,
                          },
                    )
                    e.stopPropagation()
                  }}>
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ) : undefined,
        }}
        value={stringValue}
        onClick={() => {
          if (!readOnly && !disabled) {
            setOpen(true)
          }
        }}
        placeholder={placeholder}
      />
      {open && <CategoryMenu {...props} onClose={() => setOpen(false)} inputRef={inputRef} />}
      {typeof error === 'string' && <FormHelperText error>{error}</FormHelperText>}
    </Box>
  )
}
export default InputCategory
