import { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, styled } from '@mui/material'
import {
  CarbonModel,
  CarbonModulator,
  CarbonUnit,
  Rule,
  RuleType,
} from '../../models/carbonModels.models'
import { FormItem, ItemType } from '../../models/props.models'
import ModalForm, { ModalFormProps } from '../common/ModalForm.common'
import { createOptionsFromEnum } from '../../utils/i18n.utils'
import { LangEnum } from '../../constants/langs'
import { YupUtils } from '../../utils/yup.utils'

const EndAdornmentLabel = styled(Typography)({ fontSize: '0.875rem', fontWeight: 500 })

interface ModalFormCarbonModelProps
  extends Omit<ModalFormProps, 'value' | 'items' | 'steps' | 'setValue' | 'tabs'> {
  carbonModel?: CarbonModel
}
const ModalFormCarbonModel: React.FC<ModalFormCarbonModelProps> = (props) => {
  const { t } = useTranslation()
  const { carbonModel, ...formProps } = props
  const [value, setValue] = useState<CarbonModel>(
    carbonModel ??
      ({
        name: { [LangEnum.FR]: '' },
        unit: CarbonUnit.unitary,
        productionRule: { type: RuleType.fixed },
        endOfLifeRule: { type: RuleType.fixed },
      } as CarbonModel),
  )

  useEffect(() => {
    if (value.productionRule.modulator) {
      setValue((value) => ({
        ...value,
        productionRule: {
          ...value.productionRule,
          customModulator: undefined,
        },
      }))
    }
  }, [value.productionRule.modulator])
  useEffect(() => {
    if (value.endOfLifeRule.modulator) {
      setValue((value) => ({
        ...value,
        endOfLifeRule: {
          ...value.endOfLifeRule,
          customModulator: undefined,
        },
      }))
    }
  }, [value.endOfLifeRule.modulator])
  useEffect(() => {
    if (
      value.productionRule.customModulator?.[LangEnum.FR] ||
      value.productionRule.customModulator?.[LangEnum.EN] ||
      value.productionRule.customModulator?.[LangEnum.FR_CA]
    ) {
      setValue((value) => ({
        ...value,
        productionRule: {
          ...value.productionRule,
          modulator: undefined,
        },
      }))
    }
  }, [value.productionRule.customModulator])
  useEffect(() => {
    if (
      value.endOfLifeRule.customModulator?.[LangEnum.FR] ||
      value.endOfLifeRule.customModulator?.[LangEnum.EN] ||
      value.endOfLifeRule.customModulator?.[LangEnum.FR_CA]
    ) {
      setValue((value) => ({
        ...value,
        endOfLifeRule: {
          ...value.endOfLifeRule,
          modulator: undefined,
        },
      }))
    }
  }, [value.endOfLifeRule.customModulator])

  const items: FormItem[] = useMemo(
    () => [
      ...(Object.keys(LangEnum).map((langKey: string) => ({
        type: ItemType.text,
        grid: { xs: 12, md: 4 },
        key: `name.${LangEnum[langKey as keyof typeof LangEnum]}`,
        required: LangEnum[langKey as keyof typeof LangEnum] === LangEnum.FR,
        sx: { xs: 4 },
        props: {
          label: `${t('carbonModels:attributes.name')} ${langKey}`,
          placeholder: t('carbonModels:attributes.name'),
        },
      })) as FormItem[]),
      {
        type: ItemType.select,
        grid: { xs: 6 },
        key: 'unit',
        required: true,
        props: {
          label: t('carbonModels:attributes.unit'),
          placeholder: t('global:inputs.selectPlaceholder'),
          items: createOptionsFromEnum(CarbonUnit, 'carbonModels:unit'),
        },
      },
      ...(['productionRule', 'endOfLifeRule'].map((rulekey: string) => {
        return (carbonModel: CarbonModel | undefined) => {
          const rule = carbonModel?.[rulekey as keyof CarbonModel] as Rule
          const ruleType = rule?.type
          const modulatorRule = () =>
            !rule.modulator && !rule.customModulator?.fr
              ? t('carbonModels:attributes.modulatorRequired')
              : ''

          return {
            type: ItemType.group,
            key: rulekey,
            props: {
              title: t(`carbonModels:attributes.${rulekey}` as any),
            },
            items: [
              {
                type: ItemType.select,
                grid: { xs: 6 },
                key: `${rulekey}.type`,
                required: true,
                props: {
                  label: t('carbonModels:attributes.rule.type'),
                  placeholder: t('carbonModels:attributes.rule.type'),
                  items: createOptionsFromEnum(RuleType, 'carbonModels:ruleType'),
                },
              },
              ...(ruleType !== RuleType.fixed
                ? [
                    {
                      type: ItemType.select,
                      grid: { xs: 6 },
                      key: `${rulekey}.modulator`,
                      rules: [
                        modulatorRule,
                        () =>
                          !rule.customModulator?.fr && ruleType === RuleType.items
                            ? t('carbonModels:attributes.modulatorInvalid')
                            : '',
                      ],
                      props: {
                        disabled: ruleType === RuleType.items,
                        label: t('carbonModels:attributes.modulator'),
                        placeholder: t('global:inputs.selectPlaceholder'),
                        items: createOptionsFromEnum(CarbonModulator, 'carbonModels:modulator'),
                      },
                    },
                    ...(Object.keys(LangEnum).map((langKey: string) => ({
                      type: ItemType.text,
                      grid: { xs: 12, md: 4 },
                      rules:
                        LangEnum[langKey as keyof typeof LangEnum] === LangEnum.FR
                          ? [modulatorRule]
                          : undefined,
                      key: `${rulekey}.customModulator.${
                        LangEnum[langKey as keyof typeof LangEnum]
                      }`,
                      sx: { xs: 4 },
                      props: {
                        label: `${t('carbonModels:attributes.customModulator')} ${langKey}`,
                        placeholder: t('carbonModels:attributes.customModulator'),
                      },
                    })) as FormItem[]),
                  ]
                : []),
              ...(ruleType === RuleType.intervals
                ? [
                    {
                      type: ItemType.array,
                      key: `${rulekey}.intervals`,
                      grid: { xs: 12 },
                      props: {
                        addLabel: t('carbonModels:attributes.rule.addIntervals'),
                      },
                      required: true,
                      items: [
                        {
                          type: ItemType.number,
                          grid: { xs: 4 },
                          key: 'min',
                          props: {
                            label: t('carbonModels:attributes.rule.min'),
                            placeholder: t('carbonModels:attributes.rule.min'),
                          },
                        },
                        {
                          type: ItemType.number,
                          grid: { xs: 4 },
                          key: 'max',
                          props: {
                            label: t('carbonModels:attributes.rule.max'),
                            placeholder: t('carbonModels:attributes.rule.max'),
                          },
                        },
                        {
                          type: ItemType.number,
                          grid: { xs: 4 },
                          key: 'value',
                          required: true,
                          rules: [YupUtils.FieldValidationType.positive],
                          props: {
                            min: 0,
                            label: t('carbonModels:attributes.rule.value'),
                            placeholder: t('carbonModels:attributes.rule.value'),
                            endAdornment: <EndAdornmentLabel>kgCO2eq/UF</EndAdornmentLabel>,
                          },
                        },
                      ] as FormItem[],
                    },
                  ]
                : ruleType === RuleType.items
                ? [
                    {
                      type: ItemType.array,
                      key: `${rulekey}.items`,
                      grid: { xs: 12 },
                      props: {
                        addLabel: t('carbonModels:attributes.rule.addItems'),
                      },
                      required: true,
                      items: [
                        ...(Object.keys(LangEnum).map((langKey: string) => ({
                          type: ItemType.text,
                          grid: { xs: 3 },
                          required: LangEnum[langKey as keyof typeof LangEnum] === LangEnum.FR,
                          key: `label.${LangEnum[langKey as keyof typeof LangEnum]}`,
                          sx: { xs: 4 },
                          props: {
                            label: `${t('carbonModels:attributes.rule.item')} ${langKey}`,
                            placeholder: t('carbonModels:attributes.rule.item'),
                          },
                        })) as FormItem[]),
                        {
                          type: ItemType.number,
                          grid: { xs: 3 },
                          key: 'value',
                          required: true,
                          rules: [YupUtils.FieldValidationType.positive],
                          props: {
                            min: 0,
                            label: t('carbonModels:attributes.rule.value'),
                            placeholder: t('carbonModels:attributes.rule.value'),
                            endAdornment: <EndAdornmentLabel>kgCO2eq/UF</EndAdornmentLabel>,
                          },
                        },
                      ] as FormItem[],
                    },
                  ]
                : [
                    {
                      type: ItemType.number,
                      grid: { xs: 6 },
                      key: `${rulekey}.value`,
                      required: true,
                      rules: [YupUtils.FieldValidationType.positive],
                      props: {
                        min: 0,
                        label: t('carbonModels:attributes.rule.value'),
                        placeholder: t('carbonModels:attributes.rule.value'),
                        endAdornment: <EndAdornmentLabel>kgCO2eq/UF</EndAdornmentLabel>,
                      },
                    },
                  ]),
            ],
          }
        }
      }) as FormItem[]),
    ],
    [t],
  )

  return <ModalForm {...formProps} value={value} setValue={setValue} items={items} maxWidth="lg" />
}
export default ModalFormCarbonModel
