import { useState, useMemo, useCallback, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Switch, Typography, styled, useMediaQuery, useTheme } from '@mui/material'
import { Delete as DeleteIcon, Warning as WarningIcon } from '@mui/icons-material'
import { useObservable } from '@ngneat/react-rxjs'

import {
  ManageMaterial,
  MaterialType,
  Unit,
  MaterialQuality,
  DimensionUnit,
  TermsOfSale,
  MaterialState,
  computeResourceQuantities,
  MaterialQuantity,
  QuantityType,
  convert2Imperials,
  convert2Meters,
  ImperialUnit,
  ImperialDimensionUnit,
  CerfaResource,
  CerfaWaste,
  EstimatedAge,
  Assembly,
  dimensionsToString,
  checkMaterialQuantities,
} from '../../models/materials.models'
import { FormItem, ItemType } from '../../models/props.models'
import { RetrievalModality, Route, Currency } from '../../models/commons.models'
import { sessionService } from '../../store/session'
import { materialModelsQuery } from '../../store/materialModels'
import ModalForm, { ModalFormProps } from '../common/ModalForm.common'
import { logIfDev, ObjectUtils } from '../../utils/commons.utils'
import { YupUtils } from '../../utils/yup.utils'
import { createOptionsFromEnum } from '../../utils/i18n.utils'
import MaterialTableQuantities from './TableQuantities.material'
import ModalQuantities from './ModalQuantities.material'
import Modal from '../layout/Modal.layout'

import Constants from '../../constants'
import useRoute from '../../hooks/useRoute.hooks'
import InputCategory from '../category/Input.category'
import LinkButton from '../common/button/Link.button'
import { CatalogLight } from '../../models/catalogs.models'
import constants from '../../constants'
import { traduceCategory } from '../../models/categories.models'
import CheckboxInput from '../common/input/Checkbox.input'
import { carbonModelsQuery } from '../../store/carbonModels'
import { computeCarbon, RuleType, TraducedRule } from '../../models/carbonModels.models'
import Loader from '../common/Loader.common'

const EndAdornmentLabel = styled(Typography)({ fontSize: '0.875rem', fontWeight: 500 })

interface ModalFormMaterialProps
  extends Omit<ModalFormProps, 'value' | 'items' | 'steps' | 'setValue' | 'tabs'> {
  material: Partial<ManageMaterial>
  linkedCatalog?: CatalogLight
  useImperials: boolean
  showCerfa: boolean
  checkError?: 'pem' | 'd'
  showValidationWeight?: boolean
  onHideValidationWeight?: () => Promise<void>
}
const defaultMaterial = {
  name: '',
  termsOfSale: TermsOfSale.notDefined,
  wasteOnly: false,
  totalQty: 0,
  initialQty: 0,
  currentQty: 0,
  quantities: [],
  reservedQty: 0,
  dimensions: {
    unit: DimensionUnit.centimeters,
  },
  sellByQuantityOf: 1,
  minQuantity: 0,
  cerfaResource: {},
  cerfaWaste: [],
}

const ModalFormMaterial: React.FC<ModalFormMaterialProps> = (props) => {
  const {
    showCerfa,
    checkError,
    useImperials,
    linkedCatalog,
    material,
    onSubmit,
    onClose,
    onSuccess,
    showValidationWeight,
    onHideValidationWeight,
    ...formProps
  } = props
  const { t } = useTranslation()
  const { goTo } = useRoute()
  const theme = useTheme()
  const onlyXl = useMediaQuery(theme.breakpoints.only('xl'))
  const [carbonModels] = useObservable(carbonModelsQuery.carbonModels)
  const carbonModelOptions = useMemo(
    () =>
      carbonModels.map((carbonModel) => ({
        label: carbonModel.name,
        value: carbonModel._id,
      })),
    [carbonModels],
  )
  const [hideValidationWeight, setHideValidationWeight] = useState<boolean | undefined>(false)
  const [value, setValue] = useState<ManageMaterial>(
    (useImperials
      ? convert2Imperials({
          ...defaultMaterial,
          ...material,
        })
      : {
          ...defaultMaterial,
          ...material,
        }) as ManageMaterial,
  )
  const usedCarbonModel = useMemo(() => {
    return carbonModels.find((carbonModel) => carbonModel._id === value.carbonModel?.value)
  }, [value.carbonModel, carbonModels])

  const computeCarbonTimeoutRef = useRef<any>(null)
  const [carbonLoading, setCarbonLoading] = useState<boolean>(false)
  const [carbonErrors, setCaronErrors] = useState<string[]>([])

  useEffect(() => {
    setCaronErrors([])
    setCarbonLoading(false)
    if (computeCarbonTimeoutRef.current) {
      clearTimeout(computeCarbonTimeoutRef.current)
    }

    if (usedCarbonModel) {
      setCarbonLoading(true)
      computeCarbonTimeoutRef.current = setTimeout(() => {
        try {
          ;['endOfLifeRule', 'productionRule'].forEach((ruleKey) => {
            const rule = usedCarbonModel[ruleKey as keyof typeof usedCarbonModel] as TraducedRule
            if (
              rule?.customModulator &&
              !value.technicalDetails.find(
                (technicalDetail) => technicalDetail.name === rule?.customModulator,
              )
            ) {
              setValue((value) => ({
                ...value,
                technicalDetails: [
                  { name: rule.customModulator!, value: '' },
                  ...value.technicalDetails,
                ],
              }))
            }
          })

          setCaronErrors([])
          const { productionCarbon, endOfLifeCarbon } = computeCarbon(
            usedCarbonModel,
            useImperials ? convert2Meters(value) : value,
          )

          if (
            (!!productionCarbon && value.productionCarbon !== productionCarbon) ||
            (!!endOfLifeCarbon && value.endOfLifeCarbon !== endOfLifeCarbon)
          ) {
            setValue((val) => ({
              ...val,
              productionCarbon: productionCarbon ?? val.productionCarbon,
              endOfLifeCarbon: endOfLifeCarbon ?? val.endOfLifeCarbon,
            }))
          }
        } catch (err: any) {
          setCaronErrors(err.messages)
        }
        setCarbonLoading(false)
      }, 2000)
    }
  }, [useImperials, value, usedCarbonModel])

  const [modal, setModal] = useState<'quantities' | 'validationWeight' | undefined>(undefined)

  // it won't update
  const isCreationRef = useRef(!value?._id)
  const isNeedRef = useRef(value?.type === MaterialType.need)
  const showPublicRef = useRef(sessionService.showPublic(value?.type))
  const computedShowCerfa = useMemo(() => showCerfa && !isNeedRef.current, [showCerfa])

  const disableQuantityDirectUpdate = useMemo(() => {
    if (isNeedRef.current) {
      return false
    }
    if (!isCreationRef.current) {
      return true
    }
    return !!value.cerfaWaste && value.cerfaWaste.length > 1
  }, [value.cerfaWaste])

  const [materialModels] = useObservable(materialModelsQuery.materialModels)
  const materialModelOptions = useMemo(
    () =>
      materialModels.map((materialModel) => ({
        label: `${materialModel.name} (${traduceCategory('rae', materialModel.primaryCategory)})`,
        value: materialModel._id,
        data: materialModel,
      })),
    [materialModels],
  )

  useEffect(() => {
    if (value.price) {
      setValue((currentValue) => ({ ...currentValue, termsOfSale: TermsOfSale.sale }))
    }
  }, [value.price])
  useEffect(() => {
    if (value.termsOfSale !== TermsOfSale.sale) {
      setValue((currentValue) => ({ ...currentValue, price: null }))
    }
  }, [value.termsOfSale])

  const setQuantities = useCallback((quantities: MaterialQuantity[]) => {
    setValue((currentValue: ManageMaterial) => ({
      ...currentValue,
      ...computeResourceQuantities(quantities),
    }))
  }, [])
  const handleSetValue = useCallback(
    (updateValue: (currentValue: ManageMaterial) => ManageMaterial) => {
      setValue((currentValue) => {
        const val = updateValue(currentValue)

        if ((val as any)?.materialModel) {
          const {
            _id,
            technicalDetails,
            wasteOnly,
            resourcePrimaryCategory,
            resourceSecondaryCategory,
            resourceTertiaryCategory,
            assembly,
            constituentMaterials,
            wastePrimaryCategory,
            wasteSecondaryCategory,
            wasteTertiaryCategory,
            wasteCode,
            reusePercent,
            recyclablePercent,
            backfillingPercent,
            incineratedWithEnergyPercent,
            incineratedWithoutEnergyPercent,
            nonRecoverablePercent,
            carbonModel,
            ...materialModelData
          } = ObjectUtils.copy((val as any).materialModel)

          let cerfaResource: CerfaResource = {
            primaryCategory: resourcePrimaryCategory,
            secondaryCategory: resourceSecondaryCategory,
            tertiaryCategory: resourceTertiaryCategory,
            assembly,
            constituentMaterials,
          }
          let cerfaWaste: CerfaWaste | undefined = {
            primaryCategory: wastePrimaryCategory,
            secondaryCategory: wasteSecondaryCategory,
            tertiaryCategory: wasteTertiaryCategory,
            wasteCode,
            reusePercent,
            recyclablePercent,
            backfillingPercent,
            incineratedWithEnergyPercent,
            incineratedWithoutEnergyPercent,
            nonRecoverablePercent,
          }
          if (
            !Object.keys(cerfaWaste).find((key: string) => (cerfaWaste as any)[key] !== undefined)
          ) {
            cerfaWaste = undefined
          }

          return {
            ...val,
            carbonModel: carbonModelOptions.find(
              (carbonModelOption) => carbonModelOption.value === carbonModel,
            ),
            technicalDetails: technicalDetails.map((name: string) => ({ name, value: '' })),
            ...(useImperials ? convert2Imperials(materialModelData) : materialModelData),
            wasteOnly: wasteOnly === true,
            materialModel: undefined,
            cerfaResource,
            cerfaWaste: cerfaWaste ? [cerfaWaste] : [],
          }
        }

        return val
      })
    },
    [useImperials, carbonModelOptions],
  )
  const deleteCerfa = useCallback((deletedCerfaIndex: number) => {
    setValue((currentValue) => ({
      ...currentValue,
      ...computeResourceQuantities(
        currentValue.quantities.map((quantity: MaterialQuantity) => {
          return {
            ...quantity,
            cerfaWaste:
              !quantity.cerfaWaste || quantity.cerfaWaste === deletedCerfaIndex
                ? undefined
                : quantity.cerfaWaste > deletedCerfaIndex
                ? quantity.cerfaWaste - 1
                : quantity.cerfaWaste,
          }
        }),
      ),
    }))
  }, [])

  const onMaterialSubmit = useCallback(
    async (value: ManageMaterial): Promise<any> => {
      let res = useImperials ? convert2Meters(value) : value
      if (value.wasteOnly) {
        res.initialQty = 0 // force to add unreusable if !disableQuantityDirectUpdate
        res.visible = false
        res.quantities = res.quantities
          .filter((quantity: MaterialQuantity) => quantity.initial)
          .map((quantity: MaterialQuantity) => ({ ...quantity, reusable: false }))
        delete res?.description
        delete res?.imageFilesFile
        delete res?.filesFile
        delete res?.cerfaResource
        res.dimensions = {
          unit: DimensionUnit.centimeters,
        }
        res.carbonModel = null
        res.productionCarbon = 0
        res.endOfLifeCarbon = 0
        res.sellByQuantityOf = 0
        res.minQuantity = 1
        res.technicalDetails = []
        res.termsOfSale = TermsOfSale.notDefined
      }

      if (!disableQuantityDirectUpdate && !isNeedRef.current) {
        const quantities: MaterialQuantity[] = []

        if (res.initialQty > 0) {
          quantities.push({
            _id: `local${Math.random().toString().split('.')[1]}`,
            initial: true,
            reusable: true,
            cerfaWaste: res.cerfaWaste && res.cerfaWaste.length > 0 ? 0 : undefined,
            quality: res.quality,
            type: QuantityType.inventory,
            quantity: res.initialQty,
          })
        }

        if (computedShowCerfa && res.initialQty !== res.totalQty) {
          quantities.push({
            _id: `local${Math.random().toString().split('.')[1]}`,
            initial: true,
            reusable: false,
            cerfaWaste: res.cerfaWaste && res.cerfaWaste.length > 0 ? 0 : undefined,
            quality: res.quality,
            type: QuantityType.inventory,
            quantity: res.totalQty - res.initialQty,
          })
        }

        res = {
          ...res,
          ...computeResourceQuantities(quantities),
        }
      }

      const result = await onSubmit(res)
      onSuccess?.(result)
      onClose()
    },
    [onSuccess, onClose, computedShowCerfa, onSubmit, useImperials, disableQuantityDirectUpdate],
  )
  const submitValidation = useCallback(() => {
    if (!value.unitWeight && showValidationWeight) {
      setModal('validationWeight')
    } else {
      onMaterialSubmit(value)
    }
  }, [onMaterialSubmit, value, showValidationWeight])

  const commonItems: FormItem[] = useMemo(
    () => [
      {
        type: ItemType.asyncSelect,
        key: 'materialModel',
        hideItem: !isCreationRef.current || materialModelOptions.length === 0,
        props: {
          label: t('materials:components.modalForm.materialModel'),
          searchIfEmpty: true,
          placeholder: t('global:inputs.selectPlaceholder'),
          dataValue: '_id',
          dataLabel: 'name',
          options: materialModelOptions,
          tooltip: t('materials:components.modalForm.materialModelTooltip'),
        },
      },
      {
        type: ItemType.group,
        grid: { xs: 12, sm: 6 },
        key: 'left',
        items: [
          {
            type: ItemType.text,
            key: 'name',
            required: true,
            props: {
              label: t('materials:attributes.name'),
              placeholder: t('materials:attributes.name'),
            },
          },
          {
            type: ItemType.custom,
            key: 'tertiaryCategory',
            required: true,
            custom: (
              <InputCategory
                type="rae"
                label={t('materials:attributes.category')}
                placeholder={t('materials:attributes.category')}
                required
                accurateCategory
                value={{
                  primaryCategory: value.primaryCategory,
                  secondaryCategory: value.secondaryCategory,
                  tertiaryCategory: value.tertiaryCategory,
                }}
                onChange={(categoryValue: any) => {
                  setValue((val: any) => ({
                    ...val,
                    ...categoryValue,
                  }))
                }}
              />
            ),
          },
          {
            type: ItemType.select,
            key: 'unit',
            required: true,
            grid: { xs: 6, xl: 3 },
            rules: [
              (material: ManageMaterial) => {
                return checkError === 'pem' && material.unit === Unit.tone
                  ? t('errors:pemTone')
                  : ''
              },
            ],
            props: {
              label: t('materials:attributes.unit'),
              placeholder: t('global:inputs.selectPlaceholder'),
              items: createOptionsFromEnum(useImperials ? ImperialUnit : Unit, 'materials:unit'),
            },
          },
          {
            type: ItemType.select,
            grid: { xs: 6, xl: 3 },
            key: 'quality',
            hideItem: value.wasteOnly,
            props: {
              onClick: disableQuantityDirectUpdate ? setModal.bind(null, 'quantities') : undefined,
              readOnly: disableQuantityDirectUpdate,
              label: isNeedRef.current
                ? t('materials:attributes.minimumQuality')
                : t('materials:attributes.globalQuality'),
              placeholder: t('global:inputs.selectPlaceholder'),
              items: createOptionsFromEnum(MaterialQuality, 'materials:quality'),
            },
          },
          (material: ManageMaterial) => ({
            type: ItemType.number,
            grid: { xs: 6, xl: 3 },
            key: 'totalQty',
            hideItem: !computedShowCerfa,
            rules: [
              YupUtils.FieldValidationType.positive,
              (mat: ManageMaterial) => {
                return (mat.totalQty ?? 0) < mat.initialQty
                  ? t('errors:totalSuperiorInitial')
                  : undefined
              },
            ],
            props: {
              tooltip: t('materials:attributes.quantities.totalTooltip'),
              onClick: disableQuantityDirectUpdate ? setModal.bind(null, 'quantities') : undefined,
              readOnly: disableQuantityDirectUpdate,
              endAdornment: (
                <EndAdornmentLabel>
                  {material.unit ? t(`materials:unitSymbol.${material.unit}`) : ''}
                </EndAdornmentLabel>
              ),
              label: t('materials:attributes.quantities.total'),
              placeholder: t('materials:attributes.quantities.total'),
            },
          }),
          (material: ManageMaterial) => ({
            type: ItemType.number,
            key: 'initialQty',
            grid: { xs: 6, xl: 3 },
            hideItem: value.wasteOnly,
            rules: [YupUtils.FieldValidationType.positive],
            props: {
              tooltip: isNeedRef.current
                ? undefined
                : !computedShowCerfa
                ? t('materials:attributes.quantities.initialNoCerfaTooltip')
                : t('materials:attributes.quantities.initialTooltip'),
              onClick: disableQuantityDirectUpdate ? setModal.bind(null, 'quantities') : undefined,
              readOnly: disableQuantityDirectUpdate,
              label: isNeedRef.current
                ? t('materials:attributes.desiredQty')
                : !computedShowCerfa
                ? t('materials:attributes.quantities.initialNoCerfa')
                : t('materials:attributes.quantities.initial'),
              placeholder: isNeedRef.current
                ? t('materials:attributes.desiredQty')
                : !computedShowCerfa
                ? t('materials:attributes.quantities.initialNoCerfa')
                : t('materials:attributes.quantities.initial'),
              endAdornment: (
                <EndAdornmentLabel>
                  {material.unit ? t(`materials:unitSymbol.${material.unit}`) : ''}
                </EndAdornmentLabel>
              ),
            },
          }),
          // only resources
          (material: ManageMaterial) => ({
            type: ItemType.number,
            grid: { xs: 6, xl: 3 },
            key: 'currentQty',
            hideItem: isNeedRef.current || isCreationRef.current || value.wasteOnly,
            rules: [YupUtils.FieldValidationType.positive],
            props: {
              tooltip: t('materials:attributes.quantities.currentTooltip'),
              onClick: disableQuantityDirectUpdate ? setModal.bind(null, 'quantities') : undefined,
              readOnly: disableQuantityDirectUpdate,
              endAdornment: (
                <EndAdornmentLabel>
                  {material.unit ? t(`materials:unitSymbol.${material.unit}`) : ''}
                </EndAdornmentLabel>
              ),
              label: t('materials:attributes.quantities.current'),
              placeholder: t('materials:attributes.quantities.current'),
            },
          }),
          {
            type: ItemType.group,
            key: 'dimensionsGroup',
            hideItem: isNeedRef.current || value.wasteOnly,
            summary: t('materials:attributes.dimensions.title'),
            items: [
              {
                type: ItemType.typo,
                key: 'dimensionsTitle',
                grid: { xs: 8, sm: 9 },
                formatValue: () => t('materials:attributes.dimensions.title'),
                props: {
                  variant: 'h3',
                },
              },
              {
                type: ItemType.select,
                grid: { xs: 4, sm: 3 },
                key: 'dimensions.unit',
                props: {
                  label: t('materials:attributes.dimensions.unit'),
                  placeholder: t('global:inputs.selectPlaceholder'),
                  items: createOptionsFromEnum(
                    useImperials ? ImperialDimensionUnit : DimensionUnit,
                    'materials:dimensionUnit',
                  ),
                },
              },
            ],
          },
          (material: ManageMaterial) => ({
            type: ItemType.number,
            hideItem: isNeedRef.current || value.wasteOnly,
            grid: { xs: 6, xl: 3 },
            rules: [YupUtils.FieldValidationType.positive],
            key: 'dimensions.length',
            props: {
              label: t('materials:attributes.dimensions.length'),
              placeholder: t('materials:attributes.dimensions.length'),
              endAdornment: material.dimensions?.unit ? (
                <EndAdornmentLabel>
                  {t(`materials:dimensionUnitSymbol.${material.dimensions.unit}`)}
                </EndAdornmentLabel>
              ) : undefined,
            },
          }),
          (material: ManageMaterial) => ({
            type: ItemType.number,
            hideItem: isNeedRef.current || value.wasteOnly,
            grid: { xs: 6, xl: 3 },
            rules: [YupUtils.FieldValidationType.positive],
            key: 'dimensions.width',
            props: {
              label: t('materials:attributes.dimensions.width'),
              placeholder: t('materials:attributes.dimensions.width'),
              endAdornment: material.dimensions?.unit ? (
                <EndAdornmentLabel>
                  {t(`materials:dimensionUnitSymbol.${material.dimensions.unit}`)}
                </EndAdornmentLabel>
              ) : undefined,
            },
          }),
          (material: ManageMaterial) => ({
            type: ItemType.number,
            hideItem: isNeedRef.current || value.wasteOnly,
            grid: { xs: 6, xl: 3 },
            rules: [YupUtils.FieldValidationType.positive],
            key: 'dimensions.height',
            props: {
              label: t('materials:attributes.dimensions.height'),
              placeholder: t('materials:attributes.dimensions.height'),
              endAdornment: material.dimensions?.unit ? (
                <EndAdornmentLabel>
                  {t(`materials:dimensionUnitSymbol.${material.dimensions.unit}`)}
                </EndAdornmentLabel>
              ) : undefined,
            },
          }),
          (material: ManageMaterial) => ({
            type: ItemType.number,
            hideItem: isNeedRef.current || value.wasteOnly,
            grid: { xs: 6, xl: 3 },
            rules: [YupUtils.FieldValidationType.positive],
            key: 'dimensions.diameter',
            props: {
              label: t('materials:attributes.dimensions.diameter'),
              placeholder: t('materials:attributes.dimensions.diameter'),
              endAdornment: material.dimensions?.unit ? (
                <EndAdornmentLabel>
                  {t(`materials:dimensionUnitSymbol.${material.dimensions.unit}`)}
                </EndAdornmentLabel>
              ) : undefined,
            },
          }),
          // only need
          {
            type: ItemType.date,
            grid: { xs: 6, xl: 3 },
            key: 'retrieval.endDate',
            hideItem: !isNeedRef.current,
            props: {
              label: t('materials:attributes.needsEndDate'),
            },
          },
          {
            type: ItemType.checkbox,
            key: 'uniqueDeposit',
            hideItem: !isNeedRef.current,
            props: {
              label: t('materials:attributes.uniqueDeposit'),
            },
          },
          {
            type: ItemType.text,
            key: 'description',
            hideItem: !isNeedRef.current,
            props: {
              label: t('materials:attributes.observations'),
              multiline: true,
              minRows: 2,
            },
          },
        ],
      },
      {
        type: ItemType.group,
        grid: { xs: 12, sm: 6 },
        key: 'right',
        items: [
          (material: ManageMaterial) => ({
            type: ItemType.image,
            key: 'mainImageFileFile',
            required: !isNeedRef.current && !material.wasteOnly,
            props: {
              height: onlyXl ? '256px' : '248px',
              label: t('materials:components.modalForm.addPicture'),
              maxFileSize: constants.ui.maxSize.image,
              fileDetails: material.mainImageFile,
            },
          }),
          (material: ManageMaterial) => ({
            key: 'imageFilesFile',
            type: ItemType.documents,
            hideItem: value.wasteOnly,
            grid: { xs: 12, xl: 6 },
            props: {
              cardSize: 80,
              type: 'image',
              documents: material.imageFiles,
              label: t('materials:attributes.imageFiles'),
              onSelectFiles: (newDocuments: File[]) => {
                setValue((state) => ({
                  ...state,
                  imageFilesFile: (state.imageFilesFile ?? []).concat(newDocuments),
                }))
              },
              actionIcon: <DeleteIcon />,
              onActionFile: (index: number) => {
                setValue((state) => ({
                  ...state,
                  imageFilesFile: (state.imageFilesFile ?? []).filter(
                    (_, fIndex) => fIndex !== index,
                  ),
                }))
              },
              onActionDocument: (documentId: string) =>
                setValue((state) => ({
                  ...state,
                  imageFiles: (state.imageFiles ?? []).filter((file) => file._id !== documentId),
                })),
              maxFileSize: constants.ui.maxSize.image,
            },
          }),
          (material: ManageMaterial) => ({
            key: 'filesFile',
            type: ItemType.documents,
            hideItem: value.wasteOnly,
            grid: { xs: 12, xl: 6 },
            props: {
              cardSize: 80,
              type: 'file',
              documents: material.files,
              label: t('materials:attributes.files'),
              onSelectFiles: (newDocuments: File[]) => {
                setValue((state) => ({
                  ...state,
                  filesFile: (state.filesFile ?? []).concat(newDocuments),
                }))
              },
              actionIcon: <DeleteIcon />,
              onActionFile: (index: number) => {
                setValue((state) => ({
                  ...state,
                  filesFile: (state.filesFile ?? []).filter((_, fIndex) => fIndex !== index),
                }))
              },
              onActionDocument: (documentId: string) =>
                setValue((state) => ({
                  ...state,
                  files: (state.files ?? []).filter((file) => file._id !== documentId),
                })),
              maxFileSize: constants.ui.maxSize.pdf,
            },
          }),
        ],
      },
    ],
    [
      t,
      computedShowCerfa,
      checkError,
      onlyXl,
      materialModelOptions,
      value.wasteOnly,
      value.primaryCategory,
      value.secondaryCategory,
      value.tertiaryCategory,
      useImperials,
      disableQuantityDirectUpdate,
    ],
  )

  const items: FormItem[] = useMemo(
    () => [
      {
        type: ItemType.group,
        key: 'global',
        summary: t('materials:components.modalForm.global'),
        props: {
          title: t('materials:components.modalForm.global'),
        },
        grid: { xs: 12 },
        items: commonItems,
      },
      {
        type: ItemType.group,
        key: 'sell',
        hideItem: value.wasteOnly,
        summary: t('materials:attributes.sell.title'),
        props: {
          title: t('materials:attributes.sell.title'),
        },
        items: [
          {
            type: ItemType.select,
            grid: { xs: 6, sm: 3 },
            key: 'termsOfSale',
            props: {
              label: t('materials:attributes.sell.termsOfSale'),
              placeholder: t('global:inputs.selectPlaceholder'),
              items: createOptionsFromEnum(TermsOfSale, 'materials:termsOfSale'),
            },
          },
          (material: ManageMaterial) => ({
            type: ItemType.number,
            grid: { xs: 6, sm: 3 },
            key: 'price',
            rules: [YupUtils.FieldValidationType.positive],
            required: material.termsOfSale === TermsOfSale.sale,
            props: {
              label: t('materials:attributes.sell.price'),
              placeholder: t('materials:attributes.sell.price'),
              endAdornment: (
                <EndAdornmentLabel>
                  {t(
                    `global:currency.${
                      material?.currency || (sessionService.getCountryParam('currency') as Currency)
                    }`,
                  )}
                  /{material.unit ? t(`materials:unitSymbol.${material.unit}`) : ''}
                </EndAdornmentLabel>
              ),
            },
          }),
          (material: ManageMaterial) => ({
            type: ItemType.number,
            grid: { xs: 6, sm: 3 },
            key: 'sellByQuantityOf',
            required: true,
            rules: [YupUtils.FieldValidationType.positive],
            props: {
              endAdornment: (
                <EndAdornmentLabel>
                  {material.unit ? t(`materials:unitSymbol.${material.unit}`) : ''}
                </EndAdornmentLabel>
              ),
              label: t('materials:attributes.sell.sellByQuantityOf'),
              placeholder: t('materials:attributes.sell.sellByQuantityOf'),
              min: 0,
            },
          }),
          (material: ManageMaterial) => ({
            type: ItemType.number,
            grid: { xs: 6, sm: 3 },
            key: 'minQuantity',
            rules: [
              YupUtils.FieldValidationType.positive,
              (value) =>
                !value.sellByQuantityOf ||
                !value.minQuantity ||
                value.minQuantity % value.sellByQuantityOf === 0
                  ? ''
                  : t('errors:multipleOf', {
                      value: value.sellByQuantityOf,
                    }),
            ],
            props: {
              endAdornment: (
                <EndAdornmentLabel>
                  {material.unit ? t(`materials:unitSymbol.${material.unit}`) : ''}
                </EndAdornmentLabel>
              ),
              label: t('materials:attributes.sell.minQuantity'),
              placeholder: t('materials:attributes.sell.minQuantity'),
              step: material.sellByQuantityOf ?? 1,
              min: 0,
            },
          }),
        ] as FormItem[],
      },
      {
        type: ItemType.group,
        key: 'observationsGroup',
        summary: t('materials:attributes.observations'),
        props: {
          title: t('materials:attributes.observations'),
        },
        items: [
          {
            type: ItemType.text,
            key: 'description',
            grid: { xs: 12, lg: 6 },
            hideItem: value.wasteOnly,
            props: {
              tooltip: t('materials:attributes.descriptionTooltip'),
              label: t('materials:attributes.description'),
              multiline: true,
              minRows: 2,
            },
          },
          {
            type: ItemType.text,
            key: 'privateDescription',
            grid: value.wasteOnly ? { xs: 12 } : { xs: 12, lg: 6 },
            props: {
              tooltip: t('materials:attributes.privateDescriptionTooltip'),
              label: t('materials:attributes.privateDescription'),
              multiline: true,
              minRows: 2,
            },
          },
        ],
      },
      {
        type: ItemType.group,
        key: 'technicalDetailsGroup',
        summary: t('materials:attributes.technicalDetails.title'),
        props: {
          title: t('materials:attributes.technicalDetails.title'),
        },
        items: [
          (material: ManageMaterial) => ({
            type: ItemType.number,
            grid: !material.wasteOnly && computedShowCerfa ? { xs: 4, lg: 2 } : { xs: 6, md: 3 },
            rules: [YupUtils.FieldValidationType.positive],
            key: 'unitWeight',
            required: checkError === 'd' || material.wasteOnly,
            props: {
              label: t('materials:attributes.weight.unit'),
              placeholder: t('materials:attributes.weight.unit'),
              endAdornment: material?.unit ? (
                <EndAdornmentLabel>
                  {t(`materials:attributes.weight.weightByUnit`, {
                    unit: t(`materials:unitSymbol.${material.unit}`),
                  })}
                </EndAdornmentLabel>
              ) : undefined,
            },
          }),
          (material: ManageMaterial) => ({
            type: ItemType.number,
            grid: !material.wasteOnly && computedShowCerfa ? { xs: 4, lg: 2 } : { xs: 6, md: 3 },
            key: 'reusableWeight',
            formatValue: () => (material.unitWeight ?? 0) * (material.initialQty ?? 0),
            hideItem: value.wasteOnly,
            props: {
              label: t('materials:attributes.weight.reusable'),
              placeholder: t('materials:attributes.weight.reusable'),
              readOnly: true,
              endAdornment: material?.unit ? (
                <EndAdornmentLabel>
                  {t('materials:attributes.weight.kg', { value: '' })}
                </EndAdornmentLabel>
              ) : undefined,
            },
          }),
          (material: ManageMaterial) => ({
            type: ItemType.number,
            grid: !material.wasteOnly && computedShowCerfa ? { xs: 4, lg: 2 } : { xs: 6, md: 3 },
            key: 'totalWeight',
            formatValue: () => (material.unitWeight ?? 0) * (material.totalQty ?? 0),
            hideItem: !computedShowCerfa,
            props: {
              label: t('materials:attributes.weight.total'),
              placeholder: t('materials:attributes.weight.total'),
              readOnly: true,
              endAdornment: material?.unit ? (
                <EndAdornmentLabel>
                  {t('materials:attributes.weight.kg', { value: '' })}
                </EndAdornmentLabel>
              ) : undefined,
            },
          }),
          (material: ManageMaterial) => ({
            type: ItemType.number,
            grid: !material.wasteOnly && computedShowCerfa ? { xs: 4, lg: 2 } : { xs: 6, md: 3 },
            rules: [YupUtils.FieldValidationType.positive],
            key: 'density',
            props: {
              label: t('materials:attributes.density'),
              placeholder: t('materials:attributes.density'),
              endAdornment: (
                <EndAdornmentLabel>
                  {t(`materials:attributes.weight.weightByUnit`, {
                    unit: t(`materials:unitSymbol.m3`),
                  })}
                </EndAdornmentLabel>
              ),
            },
          }),
          (material: ManageMaterial) => ({
            type: ItemType.number,
            grid: !material.wasteOnly && computedShowCerfa ? { xs: 4, lg: 2 } : { xs: 6, md: 3 },
            key: 'reusableVolume',
            hideItem: value.wasteOnly,
            formatValue: () =>
              !material.density || !material.unitWeight || !material.initialQty
                ? 0
                : (material.unitWeight * material.initialQty) / material.density,
            props: {
              label: t('materials:attributes.reusableVolume'),
              placeholder: t('materials:attributes.reusableVolume'),
              readOnly: true,
              endAdornment: <EndAdornmentLabel>{t(`materials:unitSymbol.m3`)}</EndAdornmentLabel>,
            },
          }),
          (material: ManageMaterial) => ({
            type: ItemType.number,
            grid: !material.wasteOnly && computedShowCerfa ? { xs: 4, lg: 2 } : { xs: 6, md: 3 },
            key: 'totalVolume',
            hideItem: !computedShowCerfa,
            formatValue: () =>
              !material.density || !material.unitWeight || !material.totalQty
                ? 0
                : (material.unitWeight * material.totalQty) / material.density,
            props: {
              label: t('materials:attributes.totalVolume'),
              placeholder: t('materials:attributes.totalVolume'),
              readOnly: true,
              endAdornment: <EndAdornmentLabel>{t(`materials:unitSymbol.m3`)}</EndAdornmentLabel>,
            },
          }),
          {
            type: ItemType.text,
            grid: { xs: 12, sm: 6 },
            key: 'tracksOfReuse',
            hideItem: value.wasteOnly,
            props: {
              label: t('materials:attributes.carbon.tracksOfReuse'),
              placeholder: t('materials:attributes.carbon.tracksOfReuse'),
            },
          },
          {
            type: ItemType.array,
            hideItem: value.wasteOnly,
            key: 'technicalDetails',
            props: {
              orderable: true,
              addLabel: t('materials:attributes.technicalDetails.addButton'),
            },
            items: [
              (technicalDetail) => ({
                type: ItemType.text,
                grid: { xs: 12, sm: 6 },
                key: 'name',
                required: true,
                props: {
                  disabled:
                    usedCarbonModel?.productionRule?.customModulator === technicalDetail?.name ||
                    usedCarbonModel?.endOfLifeRule?.customModulator === technicalDetail?.name,
                  label: t('materials:attributes.technicalDetails.name'),
                  placeholder: t('materials:attributes.technicalDetails.name'),
                },
              }),
              (technicalDetail) => {
                const rule =
                  usedCarbonModel?.productionRule?.customModulator === technicalDetail?.name
                    ? usedCarbonModel?.productionRule
                    : usedCarbonModel?.endOfLifeRule?.customModulator === technicalDetail?.name
                    ? usedCarbonModel?.endOfLifeRule
                    : undefined
                return {
                  type:
                    rule && rule.type === RuleType.items
                      ? ItemType.select
                      : rule
                      ? ItemType.number
                      : ItemType.text,
                  grid: { xs: 12, sm: 6 },
                  key: 'value',
                  props: {
                    label: t('materials:attributes.technicalDetails.value'),
                    placeholder: t('materials:attributes.technicalDetails.value'),
                    items: rule?.items?.map((item) => ({ value: item.label, label: item.label })),
                  },
                }
              },
            ] as FormItem[],
          },
        ],
      },
      {
        type: ItemType.group,
        key: 'retrieval',
        summary: t('materials:attributes.retrieval.title'),
        hideItem: value.wasteOnly,
        props: {
          title: t('materials:attributes.retrieval.title'),
        },
        items: [
          {
            type: ItemType.select,
            grid: { xs: 6, sm: 4 },
            key: 'state',
            props: {
              label: t('materials:attributes.state'),
              placeholder: t('global:inputs.selectPlaceholder'),
              items: createOptionsFromEnum(MaterialState, 'materials:state'),
            },
          },
          {
            type: ItemType.checkbox,
            grid: { xs: 6, sm: 4 },
            key: 'retrieval.fromDefault',
            formatValue: (fromDefault) => fromDefault !== false,
            props: {
              label: t('materials:attributes.retrieval.fromDefault'),
            },
          },
          (material: ManageMaterial) => ({
            type: ItemType.select,
            grid: { xs: 12, sm: 4 },
            key: 'retrieval.retrievalModality',
            props: {
              label: t('materials:attributes.retrieval.modality'),
              placeholder: t('global:inputs.selectPlaceholder'),
              items: createOptionsFromEnum(RetrievalModality, 'global:retrievalModality'),
            },
            hideItem: material?.retrieval?.fromDefault !== false,
          }),
          (material: ManageMaterial) => ({
            type: ItemType.date,
            grid: { xs: 6, sm: 4 },
            hideItem: material?.retrieval?.fromDefault !== false,
            key: 'retrieval.startDate',
            rules: [
              () =>
                material.retrieval &&
                material.retrieval.startDate &&
                material.retrieval.endDate &&
                new Date(material.retrieval.endDate).getTime() <
                  new Date(material.retrieval.startDate).getTime()
                  ? t('errors:startAfterEnd')
                  : undefined,
            ],
            props: {
              label: t('materials:attributes.retrieval.startDate'),
            },
          }),
          (material: ManageMaterial) => ({
            type: ItemType.date,
            grid: { xs: 6, sm: 4 },
            key: 'retrieval.endDate',
            rules: [
              () =>
                material.retrieval &&
                material.retrieval.startDate &&
                material.retrieval.endDate &&
                new Date(material.retrieval.endDate).getTime() <
                  new Date(material.retrieval.startDate).getTime()
                  ? t('errors:startAfterEnd')
                  : undefined,
            ],
            props: {
              label: t('materials:attributes.retrieval.endDate'),
            },
            hideItem: material?.retrieval?.fromDefault !== false,
          }),
          {
            type: ItemType.text,
            key: 'conditioning',
            props: {
              multiline: true,
              minRows: 2,
              label: t('materials:attributes.conditioning'),
              placeholder: t('materials:attributes.conditioning'),
            },
          },
        ] as FormItem[],
      },
      (material: ManageMaterial) => ({
        type: ItemType.group,
        key: 'quantities',
        summary: t('materials:components.modalForm.quantities'),
        props: {
          title: t('materials:components.modalForm.quantities'),
        },
        hideItem: !disableQuantityDirectUpdate,
        grid: { xs: 12 },
        rules: [
          (material: ManageMaterial) => {
            const missingQuantity = checkMaterialQuantities(
              material.quantities,
              material.cerfaWaste,
            )
            if (missingQuantity) {
              return t('errors:missingQuantity', { data: [missingQuantity] })
            }
            return ''
          },
        ],
        items: [
          {
            type: ItemType.custom,
            key: 'quantities',
            custom: (
              <MaterialTableQuantities
                showCerfa={computedShowCerfa}
                cerfaWaste={material.cerfaWaste}
                wasteOnly={material.wasteOnly}
                quantities={material.quantities}
                unit={material.unit}
                setValue={setValue}
              />
            ),
          },
          {
            type: ItemType.typo,
            key: 'total',
            grid: { xs: 12 },
            hideItem: value.wasteOnly,
            formatValue: () => t('materials:components.modalForm.totalQuantity'),
            props: {
              variant: 'subtitle2',
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 4, md: 2 },
            hideItem: !computedShowCerfa,
            key: 'totalQty',
            props: {
              readOnly: true,
              endAdornment: (
                <EndAdornmentLabel>
                  {material.unit ? t(`materials:unitSymbol.${material.unit}`) : ''}
                </EndAdornmentLabel>
              ),
              label: t('materials:attributes.quantities.total'),
              placeholder: t('materials:attributes.quantities.total'),
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 4, md: 2 },
            key: 'initialQty',
            hideItem: value.wasteOnly,
            props: {
              readOnly: true,
              endAdornment: (
                <EndAdornmentLabel>
                  {material.unit ? t(`materials:unitSymbol.${material.unit}`) : ''}
                </EndAdornmentLabel>
              ),
              label: t('materials:attributes.quantities.initial'),
              placeholder: t('materials:attributes.quantities.initial'),
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 4, md: 2 },
            key: 'currentQty',
            hideItem: isCreationRef.current || value.wasteOnly,
            props: {
              readOnly: true,
              endAdornment: (
                <EndAdornmentLabel>
                  {material.unit ? t(`materials:unitSymbol.${material.unit}`) : ''}
                </EndAdornmentLabel>
              ),
              label: t('materials:attributes.quantities.current'),
              placeholder: t('materials:attributes.quantities.current'),
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 4, md: 2 },
            key: 'reservedQty',
            hideItem: Constants.getIsLocal() || isCreationRef.current || value.wasteOnly,
            props: {
              readOnly: true,
              endAdornment: (
                <EndAdornmentLabel>
                  {material.unit ? t(`materials:unitSymbol.${material.unit}`) : ''}
                </EndAdornmentLabel>
              ),
              label: t('materials:attributes.quantities.reserved'),
              placeholder: t('materials:attributes.quantities.reserved'),
            },
          },
          {
            type: ItemType.select,
            grid: { xs: 4, md: 2 },
            key: 'quality',
            hideItem: value.wasteOnly,
            props: {
              readOnly: true,
              label: t('materials:attributes.globalQuality'),
              placeholder: t('global:inputs.selectPlaceholder'),
              items: createOptionsFromEnum(MaterialQuality, 'materials:quality'),
            },
          },
        ],
      }),
      (material: ManageMaterial) => ({
        type: ItemType.group,
        key: 'carbon',
        hideItem: value.wasteOnly,
        summary: t('materials:attributes.carbon.title'),
        props: {
          title: t('materials:attributes.carbon.title'),
        },
        items: [
          ...(carbonErrors?.length > 0 || carbonLoading
            ? [
                {
                  type: ItemType.info,
                  key: 'carbonData',
                  grid: { xs: 12 },
                  props: {
                    children: (
                      <Box display="flex">
                        {carbonLoading && (
                          <Box display="flex">
                            <Loader sx={{ marginRight: '5px' }} />
                            {t('carbonModels:attributes.rule.loading')}
                          </Box>
                        )}
                        {carbonErrors && carbonErrors.length > 0 && (
                          <Box display="flex" flexDirection="column">
                            {carbonErrors.map((error, indexError) => (
                              <Typography color="error" key={`errors.${indexError}`}>
                                {error}
                                <br />
                              </Typography>
                            ))}
                          </Box>
                        )}
                      </Box>
                    ),
                  },
                },
              ]
            : []),
          {
            type: ItemType.asyncSelect,
            key: 'carbonModel',
            grid: { xs: 12, sm: 6, md: 4 },
            props: {
              label: t('materialModels:attributes.carbon.carbonModel'),
              searchIfEmpty: true,
              placeholder: t('global:inputs.selectPlaceholder'),
              options: carbonModelOptions,
              tooltip: t('materialModels:attributes.carbon.carbonModelTooltip'),
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 12, sm: 6, md: 4 },
            rules: [YupUtils.FieldValidationType.positive],
            key: 'productionCarbon',
            props: {
              readOnly: !!usedCarbonModel,
              label: t('materials:attributes.carbon.productionCarbon'),
              placeholder: t('materials:attributes.carbon.productionCarbonPlaceholder'),
              endAdornment: <EndAdornmentLabel>kgCO2eq/UR</EndAdornmentLabel>,
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 12, sm: 6, md: 4 },
            rules: [YupUtils.FieldValidationType.positive],
            key: 'endOfLifeCarbon',
            props: {
              readOnly: !!usedCarbonModel,
              label: t('materials:attributes.carbon.endOfLifeCarbon'),
              placeholder: t('materials:attributes.carbon.endOfLifeCarbonPlaceholder'),
              endAdornment: <EndAdornmentLabel>kgCO2eq/UR</EndAdornmentLabel>,
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 12, sm: 6, md: 4 },
            rules: [YupUtils.FieldValidationType.positive],
            key: 'unitLoopCarbon',
            formatValue: () => {
              return (material.productionCarbon ?? 0) + (material.endOfLifeCarbon ?? 0)
            },
            props: {
              label: t('materials:attributes.carbon.unitLoopCarbon'),
              placeholder: t('materials:attributes.carbon.unitLoopCarbonPlaceholder'),
              readOnly: true,
              endAdornment: <EndAdornmentLabel>kgCO2eq/UR</EndAdornmentLabel>,
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 12, sm: 6, md: 4 },
            rules: [YupUtils.FieldValidationType.positive],
            key: 'loopCarbon',
            formatValue: () => {
              return (
                ((material.productionCarbon ?? 0) + (material.endOfLifeCarbon ?? 0)) *
                (material.initialQty ?? 0)
              )
            },
            props: {
              label: t('materials:attributes.carbon.loopCarbon'),
              placeholder: t('materials:attributes.carbon.loopCarbonPlaceholder'),
              readOnly: true,
              endAdornment: <EndAdornmentLabel>kgCO2eq</EndAdornmentLabel>,
            },
          },
        ],
      }),
      (material: ManageMaterial) => ({
        type: ItemType.group,
        hideItem: !computedShowCerfa || value.wasteOnly,
        key: 'cerfaResource',
        summary: t('materials:attributes.cerfaResource.summary'),
        props: {
          title: t('materials:attributes.cerfaResource.title'),
          tooltip: t('materials:attributes.cerfaResource.tooltip'),
        },
        items: [
          {
            type: ItemType.custom,
            key: 'resourceCategory',
            grid: { xs: 6 },
            rules: [() => checkError === 'pem' && !material.cerfaResource?.primaryCategory],
            custom: (
              <InputCategory
                type="resource"
                label={t('materials:attributes.cerfaResource.category')}
                placeholder={t('materials:attributes.cerfaResource.category')}
                required={checkError === 'pem'}
                accurateCategory
                value={{
                  primaryCategory: material.cerfaResource?.primaryCategory,
                  secondaryCategory: material.cerfaResource?.secondaryCategory,
                  tertiaryCategory: material.cerfaResource?.tertiaryCategory,
                }}
                onChange={(categoryValue: any) => {
                  setValue((val: any) => ({
                    ...val,
                    cerfaResource: {
                      ...val.cerfaResource,
                      ...categoryValue,
                    },
                  }))
                }}
              />
            ),
          },
          {
            key: 'cerfaDescription',
            type: ItemType.text,
            grid: { xs: 6, lg: 3 },
            formatValue: () => material.name,
            props: {
              readOnly: true,
              label: t('materials:attributes.cerfaResource.description'),
              tooltip: t('materials:attributes.cerfaResource.descriptionTooltip'),
            },
          },
          {
            key: 'cerfaQuantity',
            type: ItemType.text,
            grid: { xs: 6, lg: 3 },
            formatValue: () =>
              `${material.initialQty ?? 0} ${
                material.unit ? t(`materials:unitSymbol.${material.unit}`) : ''
              }`,
            props: {
              readOnly: true,
              label: t('materials:attributes.cerfaResource.quantity'),
              tooltip: t('materials:attributes.cerfaResource.quantityTooltip'),
            },
          },
          {
            key: 'cerfaDimensions',
            type: ItemType.text,
            grid: { xs: 6, lg: 3 },
            formatValue: () => dimensionsToString(material.dimensions),
            props: {
              readOnly: true,
              label: t('materials:attributes.cerfaResource.dimensions'),
              tooltip: t('materials:attributes.cerfaResource.dimensionsTooltip'),
            },
          },
          {
            type: ItemType.select,
            key: 'cerfaResource.assembly',
            grid: { xs: 6, lg: 3 },
            props: {
              label: t('materials:attributes.cerfaResource.assembly'),
              items: createOptionsFromEnum(Assembly, 'materials:assembly'),
            },
          },
          {
            type: ItemType.select,
            key: 'cerfaResource.estimatedAge',
            grid: { xs: 6, lg: 3 },
            props: {
              label: t('materials:attributes.cerfaResource.estimatedAge'),
              items: createOptionsFromEnum(EstimatedAge, 'materials:estimatedAge'),
            },
          },

          {
            key: 'cerfaQuality',
            type: ItemType.text,
            grid: { xs: 6, lg: 3 },
            formatValue: () => (material.quality ? t(`materials:quality.${material.quality}`) : ''),
            props: {
              readOnly: true,
              label: t('materials:attributes.cerfaResource.quality'),
              tooltip: t('materials:attributes.cerfaResource.qualityTooltip'),
            },
          },
          {
            type: ItemType.checkbox,
            key: 'cerfaResource.hazardousSuspiction',
            props: {
              children: (
                <span style={{ display: 'flex' }}>
                  <WarningIcon color="error" />
                  {t('materials:attributes.cerfaResource.hazardousSuspiction')}
                </span>
              ),
            },
          },
          {
            type: ItemType.autocomplete,
            key: 'cerfaResource.constituentMaterials',
            grid: { xs: 6 },
            props: {
              label: t('materials:attributes.cerfaResource.constituentMaterials'),
              items: [],
            },
          },

          {
            type: ItemType.typo,
            key: 'cerfaResourceCheckbox',
            formatValue: () => t('materials:attributes.cerfaResource.cerfaResourceCheckbox'),
            props: {
              variant: 'subtitle2',
            },
          },
          {
            type: ItemType.checkbox,
            key: 'cerfaResource.localized',
            grid: { xs: 6, xl: 3 },
            props: {
              label: t('materials:attributes.cerfaResource.localized'),
            },
          },
          {
            type: ItemType.checkbox,
            key: 'cerfaResource.hasReuseCondition',
            grid: { xs: 6, xl: 3 },
            props: {
              label: t('materials:attributes.cerfaResource.hasReuseCondition'),
            },
          },
          {
            type: ItemType.checkbox,
            grid: { xs: 6, xl: 3 },
            key: 'cerfaResource.hasTechnicalDetails',
            props: {
              label: t('materials:attributes.cerfaResource.hasTechnicalDetails'),
            },
          },
          {
            type: ItemType.checkbox,
            grid: { xs: 6, xl: 3 },
            key: 'cerfaResource.hasPrecautions',
            props: {
              label: t('materials:attributes.cerfaResource.hasPrecautions'),
            },
          },
        ],
      }),
      (material: ManageMaterial) => ({
        type: ItemType.group,
        hideItem: !computedShowCerfa,
        key: 'cerfaWasteGroup',
        summary: t('materials:attributes.cerfaWaste.summary'),
        props: {
          title: t('materials:attributes.cerfaWaste.title'),
          tooltip: t('materials:attributes.cerfaWaste.tooltip'),
        },
        items: [
          {
            type: ItemType.array,
            key: 'cerfaWaste',
            required: checkError === 'd',
            props: {
              onDelete: deleteCerfa,
              addLabel: t('materials:attributes.cerfaWaste.addButton'),
            },
            items: [
              (cerfaWaste: CerfaWaste, cerfaWasteIndex: number) => ({
                type: ItemType.custom,
                key: 'primaryCategory',
                required: true,
                grid: { xs: 12, md: 6 },
                custom: (
                  <InputCategory
                    type="waste"
                    label={t('materials:attributes.cerfaWaste.category')}
                    placeholder={t('materials:attributes.cerfaWaste.category')}
                    accurateCategory
                    required
                    value={{
                      primaryCategory: cerfaWaste?.primaryCategory,
                      secondaryCategory: cerfaWaste?.secondaryCategory,
                      tertiaryCategory: cerfaWaste?.tertiaryCategory,
                    }}
                    onChange={(categoryValue: any) => {
                      setValue((val: any) => ({
                        ...val,
                        cerfaWaste: val.cerfaWaste.map((valCerfa: any, valIndex: number) =>
                          valIndex === cerfaWasteIndex
                            ? {
                                ...valCerfa,
                                ...categoryValue,
                              }
                            : valCerfa,
                        ),
                      }))
                    }}
                  />
                ),
              }),
              {
                key: 'wasteCode',
                type: ItemType.text,
                grid: { xs: 12, md: 6 },
                props: {
                  label: t('materials:attributes.cerfaWaste.wasteCode'),
                },
              },
              ...(isCreationRef.current
                ? []
                : [
                    {
                      type: ItemType.typo,
                      key: 'cerfaWasteQuantities',
                      formatValue: () => t('materials:attributes.cerfaWaste.quantities'),
                      props: {
                        variant: 'subtitle2',
                      },
                    },
                    (_: CerfaWaste, cerfaWasteIndex: number) => {
                      const { totalQty } = computeResourceQuantities(
                        material.quantities?.filter((quantity: MaterialQuantity) => {
                          return (
                            (cerfaWasteIndex === 0 && !quantity.cerfaWaste) ||
                            quantity.cerfaWaste === cerfaWasteIndex
                          )
                        }) ?? [],
                      )

                      return {
                        key: 'cerfaWeight',
                        type: ItemType.number,

                        grid: { xs: 6 },
                        formatValue: () =>
                          !material.unitWeight || !totalQty
                            ? ''
                            : (material.unitWeight * totalQty) / 1000,
                        props: {
                          readOnly: true,
                          label: t('materials:attributes.cerfaWaste.weight'),
                          tooltip: t('materials:attributes.cerfaWaste.weightTooltip'),
                          endAdornment: material?.unit ? (
                            <EndAdornmentLabel>
                              {t('materials:attributes.weight.tonne', { value: '' })}
                            </EndAdornmentLabel>
                          ) : undefined,
                        },
                      }
                    },
                    (_: CerfaWaste, cerfaWasteIndex: number) => {
                      const { totalQty } = computeResourceQuantities(
                        material.quantities?.filter((quantity: MaterialQuantity) => {
                          return (
                            (cerfaWasteIndex === 0 && !quantity.cerfaWaste) ||
                            quantity.cerfaWaste === cerfaWasteIndex
                          )
                        }) ?? [],
                      )

                      return {
                        key: 'cerfaVolume',
                        type: ItemType.number,
                        grid: { xs: 6 },
                        formatValue: () =>
                          !material.density || !material.unitWeight || !totalQty
                            ? ''
                            : (material.unitWeight * totalQty) / material.density,
                        props: {
                          readOnly: true,
                          label: t('materials:attributes.cerfaWaste.volume'),
                          tooltip: t('materials:attributes.cerfaWaste.volumeTooltip'),
                          endAdornment: (
                            <EndAdornmentLabel>{t(`materials:unitSymbol.m3`)}</EndAdornmentLabel>
                          ),
                        },
                      }
                    },
                  ]),
              {
                type: ItemType.checkbox,
                grid: { xs: 12, lg: 6 },
                key: 'wasteStreamsOutlets',
                props: {
                  label: t('materials:attributes.cerfaWaste.wasteStreamsOutlets'),
                },
              },
              (cerfaWaste: CerfaWaste) => ({
                type: ItemType.typo,
                key: 'cerfaWasteValorisation',
                rules: [
                  () => {
                    const total =
                      (cerfaWaste?.reusePercent ?? 0) +
                      (cerfaWaste?.recyclablePercent ?? 0) +
                      (cerfaWaste?.backfillingPercent ?? 0) +
                      (cerfaWaste?.incineratedWithEnergyPercent ?? 0) +
                      (cerfaWaste?.incineratedWithoutEnergyPercent ?? 0) +
                      (cerfaWaste?.nonRecoverablePercent ?? 0)

                    return (checkError === 'd' || total !== 0) && total !== 100
                      ? t('errors:sumPercent')
                      : ''
                  },
                ],
                formatValue: () => t('materials:attributes.cerfaWaste.valorisation'),
                props: {
                  variant: 'subtitle2',
                },
              }),
              {
                type: ItemType.number,
                grid: { xs: 6, md: 3 },
                key: 'reusePercent',
                rules: [YupUtils.FieldValidationType.percent],
                props: {
                  label: t('materials:attributes.cerfaWaste.reusePercent'),
                  endAdornment: <EndAdornmentLabel>%</EndAdornmentLabel>,
                },
              },
              {
                type: ItemType.number,
                grid: { xs: 6, md: 3 },
                key: 'recyclablePercent',
                rules: [YupUtils.FieldValidationType.percent],
                props: {
                  label: t('materials:attributes.cerfaWaste.recyclablePercent'),
                  endAdornment: <EndAdornmentLabel>%</EndAdornmentLabel>,
                },
              },
              {
                type: ItemType.number,
                grid: { xs: 6, md: 3 },
                key: 'backfillingPercent',
                rules: [YupUtils.FieldValidationType.percent],
                props: {
                  label: t('materials:attributes.cerfaWaste.backfillingPercent'),
                  endAdornment: <EndAdornmentLabel>%</EndAdornmentLabel>,
                },
              },
              {
                type: ItemType.number,
                grid: { xs: 6, md: 3 },
                key: 'incineratedWithEnergyPercent',
                rules: [YupUtils.FieldValidationType.percent],
                props: {
                  label: t('materials:attributes.cerfaWaste.incineratedWithEnergyPercent'),
                  endAdornment: <EndAdornmentLabel>%</EndAdornmentLabel>,
                },
              },
              (cerfaWaste: CerfaWaste) => ({
                type: ItemType.typo,
                key: 'cerfaWasteElimination',
                rules: [
                  () => {
                    const total =
                      (cerfaWaste?.reusePercent ?? 0) +
                      (cerfaWaste?.recyclablePercent ?? 0) +
                      (cerfaWaste?.backfillingPercent ?? 0) +
                      (cerfaWaste?.incineratedWithEnergyPercent ?? 0) +
                      (cerfaWaste?.incineratedWithoutEnergyPercent ?? 0) +
                      (cerfaWaste?.nonRecoverablePercent ?? 0)

                    return (checkError === 'd' || total !== 0) && total !== 100
                      ? t('errors:sumPercent')
                      : ''
                  },
                ],
                formatValue: () => t('materials:attributes.cerfaWaste.elimination'),
                props: {
                  variant: 'subtitle2',
                },
              }),
              {
                type: ItemType.number,
                grid: { xs: 6, md: 3 },
                key: 'incineratedWithoutEnergyPercent',
                rules: [YupUtils.FieldValidationType.percent],
                props: {
                  label: t('materials:attributes.cerfaWaste.incineratedWithoutEnergyPercent'),
                  endAdornment: <EndAdornmentLabel>%</EndAdornmentLabel>,
                },
              },
              {
                type: ItemType.number,
                grid: { xs: 6, md: 3 },
                key: 'nonRecoverablePercent',
                rules: [YupUtils.FieldValidationType.percent],
                props: {
                  label: t('materials:attributes.cerfaWaste.nonRecoverablePercent'),
                  endAdornment: <EndAdornmentLabel>%</EndAdornmentLabel>,
                },
              },
              {
                type: ItemType.checkbox,
                grid: { xs: 12 },
                key: 'hasConditions',
                props: {
                  label: t('materials:attributes.cerfaWaste.hasConditions'),
                },
              },
            ],
          },
        ],
      }),
    ],
    [
      computedShowCerfa,
      carbonModelOptions,
      t,
      carbonLoading,
      carbonErrors,
      usedCarbonModel,
      checkError,
      commonItems,
      disableQuantityDirectUpdate,
      deleteCerfa,
      value.wasteOnly,
    ],
  )

  return (
    <>
      <ModalForm
        {...formProps}
        summary={!isNeedRef.current}
        value={value}
        setValue={handleSetValue}
        items={isNeedRef.current ? commonItems : items}
        onClose={onClose}
        onSubmit={submitValidation}
        keepOpen
        maxWidth="xl"
        header={
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="center"
            width="100%"
            padding="15px 0"
            borderBottom={`1px solid ${Constants.colors.lightBorder}`}>
            {!isCreationRef.current && (
              <Box display="flex" sx={{ marginRight: '20px' }}>
                <Typography variant="h3" sx={{ paddingTop: '7px' }}>
                  {t('materials:attributes.reference')}
                </Typography>
                <Box
                  sx={{
                    backgroundColor: Constants.colors.textFieldBackground,
                    borderRadius: '4px',
                    padding: '5px 10px',
                    height: 'fit-content',
                    marginLeft: '5px',
                  }}>
                  <Typography>{material.reference}</Typography>
                </Box>
              </Box>
            )}
            {computedShowCerfa && (
              <Box display="flex" sx={{ marginRight: '20px' }} alignItems="center">
                <Switch
                  onChange={(_, checked) => setValue((val) => ({ ...val, wasteOnly: checked }))}
                  checked={value.wasteOnly !== false}
                />
                <Typography
                  sx={{
                    marginLeft: '5px',
                  }}>
                  {t('materials:attributes.wasteOnly')}
                </Typography>
              </Box>
            )}
            {showPublicRef.current && !value.wasteOnly && (
              <Box display="flex" sx={{ marginRight: '20px' }} alignItems="center">
                <Switch
                  onChange={(_, checked) => setValue((val) => ({ ...val, visible: checked }))}
                  checked={value.visible !== false}
                />
                <Typography
                  sx={{
                    marginLeft: '5px',
                  }}>
                  {t('materials:attributes.visible')}
                </Typography>
              </Box>
            )}
            {linkedCatalog && (
              <LinkButton
                onClick={() => {
                  goTo({
                    route: Route.workspaceCatalog,
                    catalogId: linkedCatalog._id,
                  })
                }}
                sx={{ marginRight: '20px' }}>
                {t('materials:components.modalForm.linkedCatalog', {
                  catalog: linkedCatalog.name,
                })}
              </LinkButton>
            )}
            {material.originalCatalog && (
              <LinkButton
                onClick={() => {
                  if (material?.originalCatalog?._id) {
                    goTo({
                      route: Route.workspaceCatalog,
                      catalogId: material.originalCatalog._id,
                    })
                  }
                }}
                sx={{ marginRight: '20px' }}>
                {t('materials:components.modalForm.originalCatalog', {
                  catalog: material?.originalCatalog?.name,
                })}
              </LinkButton>
            )}
          </Box>
        }
      />
      {modal === 'quantities' && (
        <ModalQuantities
          showCerfa={computedShowCerfa}
          isCreation={isCreationRef.current}
          unit={value.unit}
          wasteOnly={value.wasteOnly}
          cerfaWaste={value.cerfaWaste}
          quantities={value.quantities}
          onClose={setModal.bind(null, undefined)}
          onSubmit={setQuantities}
        />
      )}
      {modal === 'validationWeight' && (
        <Modal
          maxWidth="md"
          title={t('materials:components.modalForm.validationWeightTitle')}
          closeLabel={t('materials:components.modalForm.validationCancel')}
          confirmLabel={t('materials:components.modalForm.validationSubmit')}
          onClose={() => setModal(undefined)}
          onConfirm={async () => {
            if (hideValidationWeight) {
              try {
                await onHideValidationWeight?.()
              } catch (err) {
                logIfDev(err)
              }
            }
            await onMaterialSubmit(value)
          }}>
          <>
            {t('materials:components.modalForm.validationWeightDescription')}
            {showCerfa && (
              <>
                <br />
                {t('materials:components.modalForm.validationWeightCerfaDescription')}
                <br />
                <br />
                <Box marginRight="auto">
                  <CheckboxInput
                    value={hideValidationWeight}
                    onChange={setHideValidationWeight}
                    label={t('materials:components.modalForm.hideValidationWeight')}
                  />
                </Box>
              </>
            )}
          </>
        </Modal>
      )}
    </>
  )
}
export default ModalFormMaterial
