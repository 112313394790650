import { LangEnum } from '../../constants/langs'
import { traduceCategory } from '../../models/categories.models'
import { MaterialModel } from '../../models/materialModels.models'
import { Actions } from '../../models/props.models'
import { sessionQuery } from '../../store/session'
import Card from '../common/Card.common'
import DetailsComponent from '../common/Details.common'
import { useTranslation } from 'react-i18next'

interface MaterialModelCardProps {
  value: MaterialModel
  onClick?: () => void
  actions?: Actions
  onSelect?: () => void
  selected?: boolean
}
const MaterialModelCard = ({
  value,
  onClick,
  actions,
  selected,
  onSelect,
}: MaterialModelCardProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Card
      ariaLabel="material-model-card"
      actions={actions}
      onClick={onClick}
      onSelect={onSelect}
      selected={selected}>
      <DetailsComponent
        noElevation
        title={value.name[sessionQuery.getUserLang() ?? LangEnum.FR]}
        value={value}
        items={[
          {
            label: t('materialModels:attributes.primaryCategory'),
            key: 'primaryCategory',
            formatValue: (category: number) => traduceCategory('rae', category),
          },
          {
            label: t('materialModels:attributes.secondaryCategory'),
            key: 'secondaryCategory',
            formatValue: (category: number) => traduceCategory('rae', category),
          },
          {
            label: t('materialModels:attributes.tertiaryCategory'),
            key: 'tertiaryCategory',
            formatValue: (category: number) => traduceCategory('rae', category),
          },
        ]}
      />
    </Card>
  )
}

export default MaterialModelCard
