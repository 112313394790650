import { styled, Tab, Tabs, Box, useMediaQuery, Button } from '@mui/material'
import { useTheme } from '@mui/material/styles/index.js'
import { useLocation } from 'react-router-dom'

import { useState, useEffect, forwardRef, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { Actions as ActionsInterface } from '../../models/props.models'
import Constants from '../../constants'
import Actions from './Actions.common'

const StyledTabs = styled(Tabs, {
  shouldForwardProp: (prop) => prop !== 'lightmode',
})<{ lightmode?: boolean }>(({ lightmode, theme }) => ({
  '& > div:first-of-type': {
    paddingLeft: '30px',
    paddingRight: '30px',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '0',
      paddingRight: '0',
    },
    ...(lightmode
      ? {
          border: 'none',
          '&::before': {
            backgroundColor: 'transparent',
          },
        }
      : {}),
  },
}))

interface TabItem {
  label: string
  path?: string
  group?: string
  secondary?: boolean
}

interface TabsComponentProps {
  lightMode?: boolean
  items: TabItem[]
  onChange?: (index: number) => void
  value?: number
  actions?: ActionsInterface | ActionsInterface[]
  hasError?: (index: number) => boolean
  sx?: any
}

const TabsComponent: React.FC<TabsComponentProps> = ({
  actions,
  lightMode,
  items,
  onChange,
  value,
  hasError,
  sx,
}): JSX.Element => {
  const location = useLocation()
  const hasGroup = !!items[0]?.group
  const isRoute = !!items[0]?.path
  const isAt = isRoute ? items.findIndex((item) => item.path === location.pathname) : -1
  const [current, setCurrent] = useState<number>(isAt >= 0 ? isAt : 0)

  useEffect(() => {
    const isAt = isRoute ? items.findIndex((item) => item.path === location.pathname) : -1

    setCurrent(isAt !== -1 ? isAt : value || 0)
  }, [value, location, items, isRoute])

  useEffect(() => {
    onChange?.(current)
  }, [onChange, current])

  const theme = useTheme()
  const downMd = useMediaQuery(theme.breakpoints.down('md'))

  const ActionTab = useMemo(
    () =>
      forwardRef((_, ref) => (
        <Box
          ref={ref}
          display="flex"
          sx={{
            marginLeft: 'auto',
            marginTop: hasGroup ? 'auto' : '6px',
            marginBottom: '6px',
            ...(downMd && {
              position: 'sticky',
              right: '6px',
              bottom: '6px',
            }),
          }}>
          {(actions as ActionsInterface[]).length > 0 ? (
            (actions as ActionsInterface[]).map((action: ActionsInterface, index: number) => (
              <Box marginLeft="5px" key={`action-${index}`}>
                <Actions action={action} />
              </Box>
            ))
          ) : (
            <Actions action={actions as ActionsInterface} />
          )}
        </Box>
      )),
    [actions, downMd, hasGroup],
  )

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        borderBottom: `1px solid ${Constants.colors.tabsBorder}`,
        ...sx,
      }}>
      <StyledTabs
        lightmode={lightMode}
        value={current}
        TabIndicatorProps={{
          style: {
            backgroundColor: items[current]?.secondary ? Constants.colors.secondary : undefined,
          },
        }}
        onChange={(_, newIndex) => {
          setCurrent(newIndex)
        }}>
        {items.map((item: TabItem, index: number) => (
          <Tab
            aria-label={
              item.secondary && lightMode
                ? 'light-mode-secondary'
                : item.secondary
                ? 'secondary'
                : lightMode
                ? 'light-mode-secondary'
                : undefined
            }
            sx={{ color: hasError?.(index) ? Constants.colors.error : 'inherit' }}
            key={index}
            value={index}
            label={item.label}
            component={forwardRef<any, any>((props, ref) => {
              const group = items[index]?.group
              const showGroup = group && group !== items[index - 1]?.group
              const baseComponent = props.to ? (
                <NavLink {...props} ref={ref} sx={{ alignItems: 'flex-start' }} />
              ) : (
                <Button {...props} ref={ref} sx={{ alignItems: 'flex-start' }} />
              )
              return hasGroup ? (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="84px"
                  sx={{
                    borderLeft:
                      showGroup && index !== 0 ? `1px solid ${Constants.colors.blockBorder}` : '',
                    marginLeft: showGroup && index !== 0 ? '30px' : '',
                    paddingLeft: showGroup && index !== 0 ? '30px' : '',
                    boxShadow:
                      showGroup && index !== 0 ? '-15px 0px 20px 0 rgba(0, 0, 0, 0.03)' : '',
                  }}>
                  <Box
                    display="flex"
                    alignItems="flex-end"
                    justifyContent="flex-start"
                    padding="12px 0 0 16px"
                    sx={{
                      position: 'absolute !important',
                      top: 0,
                      fontWeight: 700,
                      fontSize: 18,
                      color: item.secondary ? Constants.colors.secondary : Constants.colors.primary,
                    }}>
                    {showGroup && group}
                  </Box>
                  {baseComponent}
                </Box>
              ) : (
                baseComponent
              )
            })}
            {...(isRoute ? { to: item.path } : {})}
            wrapped
          />
        ))}

        {actions && (actions as ActionsInterface[]).length !== 0 && <ActionTab />}
      </StyledTabs>
    </Box>
  )
}

export default TabsComponent
