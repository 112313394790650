import { useCallback, useState } from 'react'

import Modal from '../components/layout/Modal.layout'
import { Capacitor } from '@capacitor/core'
import useOwnSnackbar from './useSnackbar.hooks'
import { FileUtils } from '../utils/files.utils'
import { useTranslation } from 'react-i18next'
import { Button, Grid } from '@mui/material'

const useFilePicker = (
  inputRef: HTMLInputElement | null,
  callback: (file?: any) => void,
  type?: 'file' | 'image',
): { cameraPrompt?: JSX.Element; pickImage: () => void } => {
  const [promptModal, setPromptModal] = useState<boolean>(false)
  const show = useOwnSnackbar()
  const { t } = useTranslation()

  const onConfirm = useCallback(async () => {
    try {
      const picture = await FileUtils.takePicture()
      setPromptModal(false)
      callback(picture)
    } catch (err: any) {
      show(err)
    }
  }, [show, callback])

  return {
    cameraPrompt: promptModal ? (
      <Modal
        onClose={() => {
          setPromptModal(false)
        }}
        hideAction
        maxWidth="xs"
        title={t('global:cameraPrompt.title')}>
        <Grid container spacing="5px">
          <Grid item xs={12} sm={6}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              fullWidth
              onClick={() => {
                inputRef?.click()
                setPromptModal(false)
              }}>
              {t('global:cameraPrompt.file')}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button variant="contained" color="primary" size="large" fullWidth onClick={onConfirm}>
              {t('global:cameraPrompt.picture')}
            </Button>
          </Grid>
        </Grid>
      </Modal>
    ) : undefined,
    pickImage: () => {
      if (Capacitor.isNativePlatform() && type !== 'file') {
        try {
          setPromptModal(true)
        } catch (err: any) {
          show(err)
        }
      } else {
        inputRef?.click()
      }
    },
  }
}

export default useFilePicker
