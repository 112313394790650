import { useMemo, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles/index.js'
import { useMediaQuery } from '@mui/material'
import { ColumnItem } from '../../models/props.models'
import List, { ListRef, ListProps, ListModeType, ListMode } from '../common/List.common'
import CarbonModelCard from './Card.carbonModel'
import { Traduction } from '../../models/commons.models'
import { sessionQuery } from '../../store/session'
import { LangEnum } from '../../constants/langs'
import { CarbonUnit, Rule } from '../../models/carbonModels.models'

export interface CarbonModelListProps extends Omit<ListProps, 'modes'> {}
const CarbonModelList = forwardRef<ListRef, CarbonModelListProps>((props, ref) => {
  const theme = useTheme()
  const onlyXs = useMediaQuery(theme.breakpoints.only('xs'))
  const { t } = useTranslation()
  const columns = useMemo<ColumnItem[]>(
    () => [
      {
        label: t('carbonModels:attributes.name'),
        key: 'name',
        formatValue: (name: Traduction) =>
          name[sessionQuery.getUserLang() ?? LangEnum.FR] ?? name[LangEnum.FR],
      },
      {
        label: t('carbonModels:attributes.unit'),
        key: 'unit',
        formatValue: (unit: CarbonUnit) => t(`carbonModels:unit.${unit}`),
      },
      {
        label: t('carbonModels:attributes.productionRule'),
        key: 'productionRule',
        formatValue: (rule: Rule) => {
          const type = t(`carbonModels:ruleType.${rule.type}`)
          const modulator = rule.modulator
            ? t(`carbonModels:modulator.${rule.modulator}`)
            : rule.customModulator?.[sessionQuery.getUserLang() ?? LangEnum.FR] ??
              rule.customModulator?.[LangEnum.FR]
          return `${type}${modulator ? ` : ${modulator}` : ''}`
        },
      },
      {
        label: t('carbonModels:attributes.endOfLifeRule'),
        key: 'endOfLifeRule',
        formatValue: (rule: Rule) => {
          const type = t(`carbonModels:ruleType.${rule.type}`)
          const modulator = rule.modulator
            ? t(`carbonModels:modulator.${rule.modulator}`)
            : rule.customModulator?.[sessionQuery.getUserLang() ?? LangEnum.FR] ??
              rule.customModulator?.[LangEnum.FR]
          return `${type}${modulator ? ` : ${modulator}` : ''}`
        },
      },
    ],
    [t],
  )

  return (
    <List
      ref={ref}
      {...props}
      modes={[
        {
          key: onlyXs ? 'carbonModelCard' : 'carbonModelTable',
          type: onlyXs ? ListModeType.card : ListModeType.table,
          columns: onlyXs ? undefined : columns,
          card: onlyXs ? CarbonModelCard : undefined,
        } as ListMode,
      ]}
    />
  )
})
export default CarbonModelList
