import { forwardRef, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles/index.js'
import { useMediaQuery } from '@mui/material'
import {
  Order,
  getOrderStatusColor,
  OrderStatus,
  computeOrderTotal,
} from '../../models/orders.models'
import { PlatformReference, getPlatform } from '../../models/platforms.models'
import { ItemType, ColumnItem } from '../../models/props.models'
import { User } from '../../models/users.models'
import List, { ListMode, ListModeType, ListProps, ListRef } from '../common/List.common'
import OrderCard from './Card.order'
import useRoute from '../../hooks/useRoute.hooks'
import { Route } from '../../models/commons.models'

export interface OrderListProps extends Omit<ListProps, 'modes'> {
  isClient?: boolean
  isCatalogOwner?: boolean
  isCatalogPage?: boolean
  adminPlatform?: PlatformReference
}
const OrderList = forwardRef<ListRef, OrderListProps>((props, ref) => {
  const { goTo } = useRoute()
  const theme = useTheme()
  const onlyXs = useMediaQuery(theme.breakpoints.only('xs'))
  const { t } = useTranslation()
  const { adminPlatform, isClient, isCatalogPage, ...listProps } = props

  const columns = useMemo<ColumnItem[]>(
    () => [
      { label: t('orders:attributes.orderNumber'), key: 'orderNumber' },
      (order: Order | undefined) => ({
        label: t('orders:attributes.status'),
        key: 'status',
        type: ItemType.chip,
        props: {
          full: true,
          color: order ? getOrderStatusColor(order?.status) : '',
          label: order ? t(`orders:status.${order?.status}`) : '',
        },
      }),
      ...(isClient
        ? []
        : [
            (order: Order | undefined) => ({
              label: t('orders:attributes.client'),
              key: 'client',
              type: adminPlatform ? ItemType.link : ItemType.typo,
              props: {
                icon:
                  order && adminPlatform && order.client.organization.platform !== adminPlatform
                    ? getPlatform(order.client.organization.platform).icon
                    : undefined,
                onClick: (e: any) => {
                  if (order) {
                    goTo({ route: Route.user, userId: order.client._id })
                  }
                  e.stopPropagation()
                },
              },
              formatValue: (client: User) =>
                `${order?.client.organization.name.toUpperCase()} : ${client.lastname} ${
                  client.firstname
                }`,
            }),
          ]),
      (order: Order | undefined) => ({
        label: t('orders:attributes.seller'),
        key: 'catalog.organization.name',
        type: adminPlatform ? ItemType.link : ItemType.typo,
        props: {
          onClick: (e: any) => {
            if (order) {
              goTo({ route: Route.organization, organizationId: order.catalog.organization._id })
            }
            e.stopPropagation()
          },
        },
        formatValue: () => {
          return order
            ? order.catalog.projectOwner?.name ??
                order.catalog.customProjectOwner ??
                order.catalog.organization.name
            : ''
        },
      }),
      ...(isCatalogPage
        ? []
        : [
            (order: Order | undefined) => ({
              label: t('orders:attributes.catalog'),
              key: 'catalog.name',
              type: adminPlatform ? ItemType.link : ItemType.typo,
              props: {
                icon:
                  order && adminPlatform && order.catalog.organization.platform !== adminPlatform
                    ? getPlatform(order.catalog.organization.platform).icon
                    : undefined,
                onClick: (e: any) => {
                  if (order) {
                    goTo({
                      route: Route.workspaceCatalog,
                      catalogId: order.catalog._id,
                    })
                  }
                  e.stopPropagation()
                },
                children: order?.catalog.name,
              },
            }),
          ]),
      {
        label: t('orders:attributes.products'),
        key: 'products',
        formatValue: (products: any[]) => {
          const base = products?.map((product) => product.material.name).join(', ') ?? ''
          if (base.length > 80) {
            return base.substring(0, 80) + '...'
          }
          return base
        },
      },
      {
        label: t('orders:attributes.createdAt'),
        key: 'createdAt',
        formatValue: (createdAt: string) =>
          t('global:format.date', {
            date: new Date(createdAt),
          }),
      },
      (order: Order | undefined) => ({
        label: t('orders:attributes.totalHT'),
        key: 'totalHT',
        formatValue: () => {
          if (!order) {
            return ''
          }
          if (order.status === OrderStatus.pricePending) {
            return t('orders:status.pricePending')
          }
          const total = order ? computeOrderTotal(order) : 0
          return `${(total ?? 0).toFixed(2).toString()}${t(
            `global:currency.${order.catalog.currency}`,
          )}`
        },
      }),
    ],
    [isCatalogPage, t, isClient, adminPlatform, goTo],
  )

  const card = useCallback(
    (props: any) => (
      <OrderCard
        {...props}
        adminPlatform={adminPlatform}
        isCatalogPage={isCatalogPage}
        isClient={isClient}
      />
    ),
    [adminPlatform, isCatalogPage, isClient],
  )

  return (
    <List
      ref={ref}
      {...listProps}
      modes={[
        {
          key: onlyXs ? 'orderCard' : 'orderTable',
          type: onlyXs ? ListModeType.card : ListModeType.table,
          columns: onlyXs ? undefined : columns,
          card: onlyXs ? card : undefined,
        } as ListMode,
      ]}
    />
  )
})
export default OrderList
