import React, { useEffect, useMemo, useState } from 'react'
import { Typography, Box, InputLabel, styled, Autocomplete, FormHelperText } from '@mui/material'
import { googleService } from '../../../store/google'
import { sessionService } from '../../../store/session'
import {
  LocationOn as LocationOnIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material'
import parse from 'autosuggest-highlight/parse/index.js'
import throttle from 'lodash/throttle.js'
import { useTranslation } from 'react-i18next'
import { AddressProps } from '../../../models/props.models'
import StyledInput from './Styled.input'
import { useJsApiLoader } from '@react-google-maps/api'
import { NetworkUtils } from '../../../utils/networks.utils'
import useSnackbar from '../../../hooks/useSnackbar.hooks'
import Constants from '../../../constants'
import { getInitLanguage } from '../../../utils/i18n.utils'

const LocationContainer = styled(Box)({
  '& > .MuiTypography-root': {
    lineHeight: 1.25,
  },
})
const AddressInput: React.FC<AddressProps> = (props) => {
  const {
    disabled,
    error,
    onChange,
    label,
    placeholder,
    readOnly,
    required,
    value,
    types = ['address'],
    canChooseCountry = false,
  } = props

  const libraries: any = useMemo(() => ['places', 'geometry', 'drawing'], [])
  const { t, i18n } = useTranslation()

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY as string,
    libraries,
    language: getInitLanguage(),
  })
  const show = useSnackbar()

  const [loading, setLoading] = useState(false)
  const [prediction, setPrediction] = useState<
    google.maps.places.AutocompletePrediction | undefined
  >()
  const [options, setOptions] = useState<google.maps.places.AutocompletePrediction[]>([])

  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    if (value && value.fullAddress) {
      const getPrediction = async () => {
        if (await googleService.waitForGoogle()) {
          setTimeout(getPrediction, 500)
        } else {
          setPrediction(await googleService.getSelectedPrediction(value.fullAddress))
        }
      }
      getPrediction()
    }
  }, [value])

  const fetch = useMemo(
    () =>
      throttle(
        (
          request: {
            input: string
            language?: string
            types: string[]
          },
          callback: (results?: google.maps.places.AutocompletePrediction[] | null) => void,
        ) => {
          if (!window.google?.maps?.places?.AutocompleteService || !isLoaded) {
            return
          }

          const minResults = 4
          const fecthPredictions = (request: any) => {
            return new Promise<google.maps.places.AutocompletePrediction[] | null>((resolve) => {
              return new window.google.maps.places.AutocompleteService().getPlacePredictions(
                request,
                resolve,
              )
            })
          }

          const getPredictions = async (request: any) => {
            if (Constants.specificCountry) {
              callback(
                await fecthPredictions({
                  ...request,
                  ...(Constants.specificCountry && {
                    componentRestrictions: { country: Constants.specificCountry },
                  }),
                }),
              )
              return
            }
            const countryPredictions = canChooseCountry
              ? (await fecthPredictions({
                  ...request,
                  types: ['country'],
                })) ?? []
              : []

            if (countryPredictions.length >= minResults) {
              callback(countryPredictions)
              return
            }

            const currentCountryPredictions =
              (await fecthPredictions({
                ...request,
                componentRestrictions: { country: sessionService.getCountryParam('countryCode') },
              })) ?? []

            if (currentCountryPredictions.length + countryPredictions.length >= minResults) {
              callback(
                countryPredictions.concat(currentCountryPredictions).slice(0, minResults + 1),
              )
              return
            }

            const predictions = (await fecthPredictions(request)) ?? []
            callback(
              currentCountryPredictions
                .concat(
                  predictions.filter(
                    (prediction: google.maps.places.AutocompletePrediction) =>
                      !currentCountryPredictions.find(
                        (countryPrediction: google.maps.places.AutocompletePrediction) =>
                          prediction.place_id === countryPrediction.place_id,
                      ),
                  ),
                )
                .slice(0, minResults + 1),
            )
          }
          getPredictions(request)
        },
        200,
      ),
    [canChooseCountry, isLoaded],
  )

  useEffect(() => {
    let active = true

    if (inputValue === '') {
      setOptions(prediction ? [prediction] : [])
      return undefined
    }

    if (active) {
      setLoading(true)
      fetch(
        {
          input: inputValue,
          language: i18n.language,
          types,
        },
        (results?: google.maps.places.AutocompletePrediction[] | null) => {
          let newOptions = [] as google.maps.places.AutocompletePrediction[]

          if (prediction && !results?.some((x) => x.place_id === prediction.place_id)) {
            newOptions = [prediction]
          }

          if (results) {
            newOptions = [...newOptions, ...results]
          }

          setOptions(newOptions)
          setLoading(false)
        },
      )
    }

    return () => {
      active = false
    }
  }, [prediction, inputValue, fetch, types, i18n.language])

  return (
    <Box display="flex" position="relative" flexDirection="column">
      {!!label && <InputLabel error={!!error}>{label + (required ? '*' : '')}</InputLabel>}
      <Autocomplete
        onFocus={async () => {
          if (!(await NetworkUtils.isConnected())) {
            show(t('errors:notConnected'), 'warning')
          }
        }}
        getOptionLabel={(option: google.maps.places.AutocompletePrediction) => option.description}
        filterOptions={(x) => x}
        isOptionEqualToValue={(
          option: google.maps.places.AutocompletePrediction,
          value: google.maps.places.AutocompletePrediction,
        ) => option.place_id === value.place_id}
        options={options}
        autoComplete
        includeInputInList
        value={prediction || null}
        loading={loading}
        disabled={disabled}
        readOnly={readOnly || !isLoaded}
        onChange={async (
          _: any,
          newPrediction: google.maps.places.AutocompletePrediction | null,
        ) => {
          setOptions(newPrediction ? [newPrediction, ...options] : options)
          const prediction = newPrediction || undefined
          if (prediction) {
            const location = await googleService.getAddressDetails(prediction?.place_id)
            onChange?.(location)
          } else {
            onChange?.(undefined)
          }
          setPrediction(prediction)
        }}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue)
        }}
        noOptionsText={t('global:inputs.noOptions')}
        loadingText={t('global:actions.loading')}
        popupIcon={<KeyboardArrowDownIcon />}
        renderInput={(params) => (
          <StyledInput
            {...params}
            variant="outlined"
            color="primary"
            focused={readOnly ? false : undefined}
            placeholder={placeholder}
            disabled={disabled}
            required={required}
            error={!!error && !disabled}
          />
        )}
        renderOption={(
          props: any,
          option: google.maps.places.AutocompletePrediction,
        ): React.ReactNode => {
          const matches = option.structured_formatting.main_text_matched_substrings ?? []
          const parts = parse(
            option.structured_formatting.main_text,
            matches?.map((match) => [match.offset, match.offset + match.length]),
          )
          return (
            <LocationContainer {...props} display="flex" alignItems="center">
              <Box mr="10px">
                <LocationOnIcon color="primary" />
              </Box>
              <LocationContainer>
                {parts?.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="secondaryText">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </LocationContainer>
            </LocationContainer>
          )
        }}
      />
      {typeof error === 'string' && (
        <FormHelperText
          error
          sx={{ position: 'absolute', bottom: '-2px', left: '5px', pointerEvents: 'none' }}>
          {error}
        </FormHelperText>
      )}
    </Box>
  )
}

export default AddressInput
