import { LangEnum } from '../../constants/langs'
import { CarbonModel, CarbonUnit, Rule } from '../../models/carbonModels.models'
import { Actions } from '../../models/props.models'
import { sessionQuery } from '../../store/session'
import Card from '../common/Card.common'
import DetailsComponent from '../common/Details.common'
import { useTranslation } from 'react-i18next'

interface CarbonModelCardProps {
  value: CarbonModel
  onClick?: () => void
  actions?: Actions
  onSelect?: () => void
  selected?: boolean
}
const CarbonModelCard = ({
  value,
  onClick,
  actions,
  selected,
  onSelect,
}: CarbonModelCardProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Card
      ariaLabel="carbon-model-card"
      actions={actions}
      onClick={onClick}
      onSelect={onSelect}
      selected={selected}>
      <DetailsComponent
        noElevation
        title={value.name[sessionQuery.getUserLang() ?? LangEnum.FR] ?? value.name[LangEnum.FR]}
        value={value}
        items={[
          {
            label: t('carbonModels:attributes.unit'),
            key: 'unit',
            formatValue: (unit: CarbonUnit) => t(`carbonModels:unit.${unit}`),
          },
          {
            label: t('carbonModels:attributes.productionRule'),
            key: 'productionRule',
            formatValue: (rule: Rule) => {
              const type = t(`carbonModels:ruleType.${rule.type}`)
              const modulator = rule.modulator
                ? t(`carbonModels:modulator.${rule.modulator}`)
                : rule.customModulator?.[sessionQuery.getUserLang() ?? LangEnum.FR] ??
                  rule.customModulator?.[LangEnum.FR]
              return `${type}${modulator ? ` : ${modulator}` : ''}`
            },
          },
          {
            label: t('carbonModels:attributes.endOfLifeRule'),
            key: 'endOfLifeRule',
            formatValue: (rule: Rule) => {
              const type = t(`carbonModels:ruleType.${rule.type}`)
              const modulator = rule.modulator
                ? t(`carbonModels:modulator.${rule.modulator}`)
                : rule.customModulator?.[sessionQuery.getUserLang() ?? LangEnum.FR] ??
                  rule.customModulator?.[LangEnum.FR]
              return `${type}${modulator ? ` : ${modulator}` : ''}`
            },
          },
        ]}
      />
    </Card>
  )
}

export default CarbonModelCard
